import { ChangeEvent, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setTaskParams } from "../redux/tasksSlice";
import { GetTaskParams, TaskTypes } from "../types/tasks";

const useTaskHandlers = <T extends TaskTypes>() => {
	const dispatch = useDispatch();

	const createInputParamsHandler = useCallback(
		(property: keyof GetTaskParams<T>) =>
			(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				dispatch(setTaskParams({ [property]: event.target.value }));
			},
		[dispatch],
	);

	return { createInputParamsHandler };
};

export default useTaskHandlers;
