import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { RootState } from "../../redux/store";
import {
	deleteChapterAsync,
	getChaptersAsync,
} from "../../redux/chaptersSlice";
import penIcon from "../../img/pen.svg";

import BinIcon from "../BinIcon";
import Svg from "../Svg";
import { Table, TableContainer, TableHead, TableHeadCell } from "../Table";
import TaskLevel from "../TaskLevel";
import Text from "../Text";

import { ChaptersTableProps } from "./interface";
import { Chapter } from "../../types/chapters";

const BrownCell = styled.td<{ isRightEdge?: boolean }>`
  padding: 0;

  > div {
    display: inline-flex;
    width: 100%;
    height: 38px;
    padding: 8px 0;
    background-color: #a78772;

    > div,
    span {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
    }
  }

  & + & {
    > div {
      > div {
        border-left: 1px solid white;
      }
    }
  }

  ${({ isRightEdge }) => {
		if (isRightEdge) {
			return css`
        > div {
          border-radius: 0px 7px 7px 0;
        }
      `;
		}
	}};
`;

const TableIcon = styled(Svg).attrs({ src: penIcon, color: "white" })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #eb5757;
  border-radius: 8px;
  width: 49px;
  height: 38px;
  padding: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledBinIcon = styled(BinIcon).attrs({ color: "white" })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width: 49px;
  padding: 8px;
  background-color: #eb5757;
  border-radius: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledTableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;

  td {
    text-align: center;
  }
`;

const ChaptersTable = ({ updateChapters }: ChaptersTableProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { chapters } = useSelector((state: RootState) => state.chapter);

	const createIconHandler = useCallback(
		(type: "edit" | "delete", chapter: Chapter) => async () => {
			if (type === "edit") {
				history.push(`/chapters/${chapter._id}`);
			} else if (type === "delete") {
				await dispatch(
					deleteChapterAsync(chapter._id, () => {
						dispatch(getChaptersAsync());
					}),
				);
				updateChapters();
			}
		},
		[dispatch, history, updateChapters],
	);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<tr>
						<TableHeadCell>№</TableHeadCell>
						<TableHeadCell>уровень</TableHeadCell>
						<TableHeadCell>название</TableHeadCell>
						<TableHeadCell>ред.</TableHeadCell>
						<TableHeadCell>удалить</TableHeadCell>
					</tr>
				</TableHead>
				<StyledTableBody>
					{chapters.map((chapter) => (
						<tr key={chapter._id}>
							<td>
								<Text color='#a78772' weight={700} size={18}>
									{chapter.number}
								</Text>
							</td>
							<BrownCell>
								<div>
									<div>
										<TaskLevel level={chapter.level} />
									</div>
								</div>
							</BrownCell>
							<BrownCell isRightEdge>
								<div>
									<Text color='white' size={18}>
										{chapter.title}
									</Text>
								</div>
							</BrownCell>
							<td>
								<div>
									<TableIcon onClick={createIconHandler("edit", chapter)} />
								</div>
							</td>
							<td>
								<div>
									<StyledBinIcon
										onClick={createIconHandler("delete", chapter)}
									/>
								</div>
							</td>
						</tr>
					))}
				</StyledTableBody>
			</Table>
		</TableContainer>
	);
};

export default ChaptersTable;
