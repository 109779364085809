import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { gql } from "graphql-request";

import client from "../utils/request";
import { Chapter } from "../types/chapters";

import { AppThunk, RootState } from "./store";

interface ChaptersState {
	chapters: Chapter[];
	chapter?: Chapter;
	count: number;
}

const initialState: ChaptersState = {
	chapters: [],
	count: 0,
};

const chaptersSlice = createSlice({
	name: "chapters",
	initialState,
	reducers: {
		setChapters: (state, action) => {
			state.chapters = action.payload.list;
			state.count = 0;
		},
		initChapter: (state, action: PayloadAction) => {
			state.chapter = {
				level: "1",
				title: "",
				number: 1,
			} as Chapter;
		},
		setChapter: (
			state,
			action: PayloadAction<Partial<Omit<Chapter, "params">>>,
		) => {
			state.chapter = { ...state.chapter, ...action.payload } as Chapter;
		},
		clearChapter: (state) => {
			state.chapter = undefined;
		},
		clearChapters: (state) => {
			state.chapters = [];
			state.count = 0;
		},
	},
});

export const {
	setChapter,
	clearChapter,
	initChapter,
	clearChapters,
	setChapters,
} = chaptersSlice.actions;

export const selectChapter = (state: RootState) => state.chapter.chapter;

export const getChaptersAsync = (): AppThunk => async (dispatch, getState) => {
	try {
		const response = await client.request(
			gql`
        query GetChapters {
          chapters {
            list {
              _id
              level
              title
              number
            }
          }
        }
      `,
		);

		dispatch(setChapters(response.chapters));
	} catch (e) {}
};

export const requestChapter =
	(id: string): AppThunk =>
	async (dispatch) => {
		try {
			const response = await client.request(
				gql`
          query GetChapter($id: String!) {
              chapters(query: { _id: $id }) {
                  list {
                      _id
                      title
                      level
                      number
                  }
              }
          }
      `,
				{ id },
			);
			dispatch(setChapter(response.chapters.list[0]));

			return response.chapters.list[0];
		} catch (error) {}
	};

export const createChapterAsync =
	(
		chapter: { title: string; level: string; number: number },
		success: () => void,
	): AppThunk =>
	async (dispatch) => {
		if (!chapter) {
			return;
		}

		try {
			await client.request(
				gql`
        mutation CreateChapter($chapter: ChapterCreateData!) {
          createChapter(chapterData: $chapter)
        }
      `,
				{ chapter },
			);

			success();
		} catch (error: any) {
			alert(error.response?.errors[0]?.message);
		}
	};

export const updateChapterAsync =
	(
		id: string,
		chapter: Partial<{ title: string; level: string; number: number }>,
		success: () => void,
	): AppThunk =>
	async (dispatch) => {
		if (!chapter) {
			return;
		}

		try {
			await client.request(
				gql`
        mutation UpdateChapter($id: String!, $chapter: ChapterUpdateData!) {
          updateChapter(_id: $id, chapterData: $chapter)
        }
      `,
				{ chapter, id },
			);

			success();
		} catch (error: any) {
			alert(error.response?.errors[0]?.message);
		}
	};

export const deleteChapterAsync =
	(id: string, success: () => void): AppThunk =>
	async (dispatch) => {
		try {
			// TODO: обработать ошибки
			await client.request(
				gql`
        mutation DeleteChapter($id: String!) {
          deleteChapter(_id: $id)
        }
      `,
				{ id },
			);
		} catch (e) {}
	};

export default chaptersSlice.reducer;
