import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import HeaderBackground from "../HeaderBackground";
import HeaderSearch from "../HeaderSearch";
import HeaderTitle from "../HeaderTitle";
import NavBar from "../NavBar";
import useRoutes from "../Router/useRoutes";
import Svg from "../Svg";
import exitIcon from "../../img/exit.svg";
import { setIsAuth } from "../../redux/userSlice";

const Container = styled.header`
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 81px;
  padding-right: 60px;
  margin-top: 48px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`;

const ExitButton = styled(Svg).attrs({
	src: exitIcon,
	size: 40,
	color: "white",
})`
  margin-left: 50px;
  cursor: pointer;
  user-select: none;
`;

const Header = () => {
	const dispatch = useDispatch();
	const { currentRoute } = useRoutes();

	const logout = () => {
		dispatch(setIsAuth(false));
	};

	return (
		<Container>
			<HeaderBackground color={currentRoute.header.color} />
			<HeaderTitle>{currentRoute.header.title}</HeaderTitle>
			<HeaderSearch />
			<Content>
				<NavBar />
				<ExitButton onClick={logout} />
			</Content>
		</Container>
	);
};

export default Header;
