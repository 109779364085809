import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import styled from "styled-components";

import useFileUpload from "../../utils/useFileUpload";

import BinIcon from "../BinIcon";
import Button from "../Button";
import Flex from "../Flex";
import Svg from "../Svg";
import Text from "../Text";

import { VideoUploadProps } from "./interface";
// import videoIcon from "./video.svg";
import playIcon from "./play.svg";
import equalizeIcon from "./equalize.svg";

const VideoUploadButton = styled(Button)`
  position: relative;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  padding: 6px 12px;
  background-color: #27ae60;
  color: white;
  font-weight: 500;
  font-size: 18px;

  span {
    margin-left: 9px;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    padding: 0;
  }
`;

const EqualizeContainer = styled.div`
  display: inline-flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: -24px;
  margin-left: 12px;
  margin-right: 16px;

  ${Text} {
    margin-top: 4px;
  }
`;

const Audio = styled.video`
  //display: none;
`;

// const videoIconSize = [17, 23];

const VideoUpload = ({ src, onChange }: VideoUploadProps) => {
	const [duration, setDuration] = useState("00:00");

	const videoRef = useRef<HTMLVideoElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		const video = videoRef.current;

		if (!video) {
			return;
		}

		if (src) {
			video.oncanplay = () => {
				const mins = ~~((video.duration % 3600) / 60);
				const secs = ~~video.duration % 60;

				let ret = "";

				ret += `${mins}:${secs < 10 ? "0" : ""}`;
				ret += `${secs}`;

				setDuration(ret);
			};
		}

		return () => {
			video.oncanplay = null;
		};
	}, [src]);

	useEffect(() => {
		const video = videoRef.current;

		if (!video) {
			return;
		}

		if (isPlaying) {
			video.play();
		}

		return () => {
			video.pause();
			video.currentTime = 0;
		};
	}, [isPlaying]);

	const { uploadFile } = useFileUpload();

	const handleChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) {
				return;
			}

			const file = event.target.files[0];

			const path = await uploadFile(file);

			onChange(path);
		},
		[uploadFile, onChange],
	);

	const togglePlay = useCallback(() => {
		setIsPlaying((prevState) => !prevState);
	}, []);

	const deleteAudio = useCallback(() => {
		onChange("");
		setIsPlaying(false);
	}, [onChange]);

	if (!src) {
		return (
			<VideoUploadButton>
				<input
					accept='video/mp4,video/x-m4v,video/*'
					type='file'
					value=''
					onChange={handleChange}
				/>
				{/* <Svg src={videoIcon} size={videoIconSize} /> */}
				<span>Добавить видео</span>
			</VideoUploadButton>
		);
	}

	return (
		<Flex alignItems='center'>
			<Svg onClick={togglePlay} src={playIcon} isInitialColor />
			<BinIcon
				color='#27ae60'
				onClick={deleteAudio}
				style={{ marginLeft: 8 }}
			/>
			<Audio src={src} ref={videoRef} />
		</Flex>
	);
};

export default VideoUpload;
