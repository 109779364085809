import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { gql } from "graphql-request";

import client from "../utils/request";
import { GetTaskParams, Task, TaskTypes } from "../types/tasks";

import { AppThunk, RootState } from "./store";

interface TasksState {
	tasks: Task[];
	task?: Task;
	count: number;
	page: number;
}

const initTaskParams: { [Key in TaskTypes]: GetTaskParams<Key> } = {
	[TaskTypes.images]: {
		photos: [],
		answer: "",
		text: "",
	},
	[TaskTypes.correctTranslate]: {
		text: "",
		answer: "",
		answers: [],
	},
	[TaskTypes.audio]: {
		sound: "",
		answerArray: [],
		answers: [],
		text: "",
	},
	[TaskTypes.audioFreeAnswer]: {
		sound: "",
		answer: "",
		text: "",
	},
	[TaskTypes.space]: {
		text: "",
		answer: "",
		answers: [],
	},
	[TaskTypes.video]: {
		video: "",
		answerArray: [],
		answers: [],
		text: "",
	},
	[TaskTypes.pairs]: {
		text: "",
		answers: [],
	},
	[TaskTypes.freeAnswer]: {
		text: "",
		answer: "",
	},
	[TaskTypes.wordByLetters]: {
		text: "",
		answer: "",
	},
};

const initialState: TasksState = {
	tasks: [],
	count: 0,
	page: 1,
};

const tasksSlice = createSlice({
	name: "tasks",
	initialState,
	reducers: {
		setTasks: (state, action) => {
			state.tasks = action.payload.list;
			state.count = action.payload.meta.count;
		},
		initTask: (state, action: PayloadAction<TaskTypes>) => {
			const type = action.payload;

			state.task = {
				level: "1",
				points: 0,
				active: false,
				type,
				params: { ...initTaskParams[type] },
			} as Task;
		},
		setTask: (state, action: PayloadAction<Partial<Omit<Task, "params">>>) => {
			state.task = { ...state.task, ...action.payload } as Task;
		},
		setTaskParams: (
			state: TasksState,
			action: PayloadAction<Partial<GetTaskParams<any>>>,
		) => {
			if (state.task) {
				state.task.params = { ...state.task.params, ...action.payload };
			}
		},
		clearTask: (state) => {
			state.task = undefined;
		},
		clearTasks: (state) => {
			state.tasks = [];
			state.count = 0;
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
	},
});

const { setTasks } = tasksSlice.actions;
export const {
	setTask,
	clearTask,
	initTask,
	setTaskParams,
	clearTasks,
	setPage,
} = tasksSlice.actions;

export const selectTask = (state: RootState) => state.tasks.task;

export const requestTasks =
	(
		type: TaskTypes,
		params: Partial<{ page: number; limit: number; level?: string }>,
	): AppThunk =>
	async (dispatch) => {
		try {
			const response = await client.request(
				gql`
        query GetTasks($type: String, $level: String, $page: Int, $limit: Int) {
          tasks(type: $type, query: { level: $level }, pagination: { page: $page, limit: $limit }) {
            list {
              _id
              type
							chapterId
              level
              points
              active
              number
            }
            meta {
              count
            }
          }
        }
      `,
				{ type, ...params },
			);
			dispatch(setTasks(response.tasks));
		} catch (error) {}
	};

export const requestTask =
	(id: string, type: TaskTypes): AppThunk =>
	async (dispatch) => {
		try {
			const params = initTaskParams[type];
			const paramsKeys = Object.keys(params).join("\n");

			const response = await client.request(
				gql`
          query GetTask($id: String!, $type: String!) {
              tasks(type: $type, query: { _id: $id }) {
                  list {
                      _id
                      type
											chapterId
                      level
                      points
                      active
                      number
                      params {
                          ${paramsKeys}
                      }
                  }
              }
          }
      `,
				{ id, type },
			);
			dispatch(setTask(response.tasks.list[0]));
		} catch (error) {}
	};

export const createTask =
	(success: () => void): AppThunk =>
	async (dispatch, getState) => {
		const task = getState().tasks.task;

		if (!task) {
			return;
		}

		try {
			await client.request(
				gql`
        mutation CreateTask($task: TaskCreateData!) {
          createTask(taskData: $task)
        }
      `,
				{ task },
			);

			success();
		} catch (error: any) {
			alert(error.response?.errors[0]?.message);
		}
	};

export const updateTask =
	(success: () => void): AppThunk =>
	async (dispatch, getState) => {
		const taskState = getState().tasks.task;

		if (!taskState) {
			return;
		}

		const { _id, type, ...task } = taskState;

		try {
			await client.request(
				gql`
        mutation UpdateTask($id: String!, $task: TaskUpdateData!) {
          updateTask(_id: $id, taskData: $task)
        }
      `,
				{ task, id: _id },
			);

			success();
		} catch (error: any) {
			alert(error.response?.errors[0]?.message);
		}
	};

export const deleteTask =
	(id: string): AppThunk =>
	async (dispatch) => {
		try {
			// TODO: обработать ошибки
			await client.request(
				gql`
        mutation DeleteTask($id: String!) {
          deleteTask(_id: $id)
        }
      `,
				{ id },
			);
		} catch (e) {}
	};

export default tasksSlice.reducer;
