import styled from "styled-components";
import { FlexProps } from "./interface";

const Flex = styled.div<FlexProps>`
  display: ${(props) => (props.isInline ? "inline-flex" : "flex")};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  flex-wrap: ${(props) => props.wrap};
`;

export default Flex;
