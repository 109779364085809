import React, { useCallback } from "react";
import ImageUpload from "../ImageUpload";
import Svg from "../Svg";
import {
	StyledAboutPageFormImages,
	StyledImageUploadContainer,
} from "./AboutPageFormImagesStyled";
import { AboutPageFormImagesProps } from "./interface";
import plusIcon from "../../img/plus.svg";

const AboutPageFormImages = ({
	photos,
	onChange,
}: AboutPageFormImagesProps) => {
	const handleChange = useCallback(
		(path: string, index: number) => {
			if (!path) {
				const nextPhotos = photos.slice();
				nextPhotos.splice(index, 1);
				onChange(nextPhotos);
			} else {
				const nextPhotos = photos.slice();
				nextPhotos[index] = path;
				onChange(nextPhotos);
			}
		},
		[onChange, photos],
	);

	const addEmptyPhoto = useCallback(() => {
		onChange([...photos, ""]);
	}, [onChange, photos]);

	return (
		<StyledAboutPageFormImages>
			{!photos.length && (
				<StyledImageUploadContainer>
					<ImageUpload
						onChange={(path) => handleChange(path, 0)}
						key='default'
					/>
					<Svg src={plusIcon} color='#796553' onClick={addEmptyPhoto} />
				</StyledImageUploadContainer>
			)}
			{photos.map((photo, index) => (
				<StyledImageUploadContainer>
					<ImageUpload
						onChange={(path) => handleChange(path, index)}
						key={index}
						file={photo}
					/>
					<Svg src={plusIcon} color='#796553' onClick={addEmptyPhoto} />
				</StyledImageUploadContainer>
			))}
		</StyledAboutPageFormImages>
	);
};

export default AboutPageFormImages;
