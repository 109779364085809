import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AboutPageSidebar from "../../components/AboutPageSidebar";
import ButtonBlue from "../../components/ButtonBlue";
import FormGroup from "../../components/FormGroup";
import FormInput from "../../components/FormInput";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../../components/FormGroup/interface";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import ImageUpload from "../../components/ImageUpload";
import Page from "../../components/Page";
import { getAboutAsync, updateBannerAsync } from "../../redux/aboutSlice";
import { RootState } from "../../redux/store";

const AboutBannerPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAboutAsync());
	}, [dispatch]);

	const storeBanner = useSelector((state: RootState) => state.about.banner);

	const [banner, setBanner] = useState("");
	const [link, setLink] = useState("");

	useEffect(() => {
		setBanner(storeBanner.src);
		setLink(storeBanner.link);
	}, [storeBanner]);

	const save = useCallback(async () => {
		if (banner !== storeBanner.src && link !== storeBanner.link) {
			try {
				await dispatch(updateBannerAsync(banner, link));

				alert("Изменения успешно сохранены");
			} catch (e) {
				alert("Ошибка при сохранении изменений");
			}
		}
	}, [dispatch, banner, storeBanner, link]);

	return (
		<Page sidebar={<AboutPageSidebar />}>
			<GoBackButton
				to="/"
				label="вернуться на главную"
				backgroundColor="#86C6A7"
			/>
			<FormGroup
				labelPlacement={FormGroupLabelPlacements.top}
				label="Баннер"
				margin={FormGroupMargins.normal}
			>
				<ImageUpload onChange={setBanner} file={banner} />
			</FormGroup>
			<FormGroup
				margin={FormGroupMargins.normal}
				label="Ссылка"
				labelPlacement={FormGroupLabelPlacements.top}
			>
				<FormInput
					value={link}
					onChange={({ target }) => setLink(target.value)}
				/>
			</FormGroup>
			<ButtonBlue onClick={save}>Сохранить</ButtonBlue>
		</Page>
	);
};

export default AboutBannerPage;
