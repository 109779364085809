import styled from "styled-components";
import Svg from "../Svg";

export const StyledAboutPageFormImages = styled.div`
  display: flex;
  overflow-x: auto;
`;

export const StyledImageUploadContainer = styled.div`
  flex: none;
  display: inline-flex;
  align-items: flex-start;

  ${Svg} {
    cursor: pointer;
    user-select: none;
    margin-left: 20px;
  }

  & + & {
    margin-left: 16px;
  }
`;
