import React from "react";
import { Switch, Route } from "react-router-dom";
import useRoutes from "./useRoutes";

const Router = () => {
	const { routes } = useRoutes();

	return (
		<Switch>
			{routes.map((route) => (
				<Route
					component={route.component}
					key={route.id}
					path={route.path}
					exact
				/>
			))}
		</Switch>
	);
};

export default Router;
