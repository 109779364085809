import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { TaskFive, TaskOne, TaskTwo, TaskTypes } from "../../types/tasks";
import useTaskHandlers from "../../utils/useTaskHandlers";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import Textarea from "../Textarea";

const TaskTextEditor = () => {
	const { createInputParamsHandler } = useTaskHandlers<TaskTypes.images>();

	const text = useSelector((state: RootState) => {
		const params = state.tasks.task?.params as
			| TaskOne["params"]
			| TaskTwo["params"]
			| TaskFive["params"]
			| undefined;

		if (params) {
			return params.text;
		}

		return "";
	});

	return (
		<FormGroup
			margin={FormGroupMargins.normal}
			label='Ввести задание (не более 70 знаков):'
			labelPlacement={FormGroupLabelPlacements.top}
		>
			<Textarea
				rows={6}
				onChange={createInputParamsHandler("text")}
				value={text}
			/>
		</FormGroup>
	);
};

export default TaskTextEditor;
