import styled from "styled-components";
import { rgba } from "polished";
import SidebarBlock from "../SidebarBlock";

export const StyledDictionarySidebar = styled.div`
  max-width: 267px;
  margin-right: 53px;
  padding: 20px 8px;
  background-color: ${rgba("#565BDF", 0.7)};

  ${SidebarBlock} {
    padding: 20px 8px;
  }
`;
