import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import styled from "styled-components";
import useFileUpload from "../../utils/useFileUpload";
import BinIcon from "../BinIcon";
import Button from "../Button";
import Flex from "../Flex";
import Svg from "../Svg";
import Text from "../Text";
import { AudioUploadProps } from "./interface";
import audioIcon from "./audio.svg";
import playIcon from "./play.svg";
import equalizeIcon from "./equalize.svg";

const AudioUploadButton = styled(Button)`
  position: relative;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  padding: 6px 12px;
  background-color: #27ae60;
  color: white;
  font-weight: 500;
  font-size: 18px;

  span {
    margin-left: 9px;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    padding: 0;
  }
`;

const EqualizeContainer = styled.div`
  display: inline-flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: -24px;
  margin-left: 12px;
  margin-right: 16px;

  ${Text} {
    margin-top: 4px;
  }
`;

const Audio = styled.audio`
  //display: none;
`;

const audioIconSize = [17, 23];

const AudioUpload = ({ src, onChange }: AudioUploadProps) => {
	const [duration, setDuration] = useState("00:00");

	const audioRef = useRef<HTMLAudioElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		const audio = audioRef.current;

		if (!audio) {
			return;
		}

		if (src) {
			audio.oncanplay = () => {
				const mins = ~~((audio.duration % 3600) / 60);
				const secs = ~~audio.duration % 60;

				let ret = "";

				ret += "" + mins + ":" + (secs < 10 ? "0" : "");
				ret += "" + secs;

				setDuration(ret);
			};
		}

		return () => {
			audio.oncanplay = null;
		};
	}, [src]);

	useEffect(() => {
		const audio = audioRef.current;

		if (!audio) {
			return;
		}

		if (isPlaying) {
			audio.play();
		}

		return () => {
			audio.pause();
			audio.currentTime = 0;
		};
	}, [isPlaying]);

	const { uploadFile } = useFileUpload();

	const handleChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) {
				return;
			}

			const file = event.target.files[0];

			const path = await uploadFile(file);

			onChange(path);
		},
		[uploadFile, onChange],
	);

	const togglePlay = useCallback(() => {
		setIsPlaying((prevState) => !prevState);
	}, []);

	const deleteAudio = useCallback(() => {
		onChange("");
		setIsPlaying(false);
	}, [onChange]);

	if (!src) {
		return (
			<AudioUploadButton>
				<input accept='audio/*' type='file' value='' onChange={handleChange} />
				<Svg src={audioIcon} size={audioIconSize} />
				<span>Добавить аудио</span>
			</AudioUploadButton>
		);
	}

	return (
		<Flex alignItems='center'>
			<Svg onClick={togglePlay} src={playIcon} isInitialColor />
			<EqualizeContainer>
				<Svg src={equalizeIcon} isInitialColor />
				<Text color='#666666' size={18} weight={300}>
					{duration}
				</Text>
			</EqualizeContainer>
			<BinIcon color='#27ae60' onClick={deleteAudio} />
			<Audio src={src} ref={audioRef} />
		</Flex>
	);
};

export default AudioUpload;
