import React from "react";
import styled, { css } from "styled-components";
import Label from "../Label";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
	FormGroupProps,
} from "./interface";

const FormGroupContainer = styled.div<{
	margin?: FormGroupMargins;
	labelPlacement?: FormGroupLabelPlacements;
}>`
  display: flex;
  margin: ${(props) => {
		if (props.margin === FormGroupMargins.normal) {
			return "16px 0";
		}
	}};
  ${(props) => {
		if (props.labelPlacement === FormGroupLabelPlacements.top) {
			return css`
        flex-direction: column;

        ${Label} {
          margin-bottom: 12px;
        }
      `;
		}

		if (props.labelPlacement === FormGroupLabelPlacements.left) {
			return css`
        align-items: center;
        justify-content: space-between;
      `;
		}
	}};
`;

const FormGroup = ({
	label,
	children,
	margin,
	labelPlacement = FormGroupLabelPlacements.left,
	className,
}: FormGroupProps) => {
	return (
		<FormGroupContainer
			margin={margin}
			labelPlacement={labelPlacement}
			className={className}
		>
			{label && <Label>{label}</Label>}
			{children}
		</FormGroupContainer>
	);
};

export default FormGroup;
