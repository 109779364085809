import React from "react";
import { StyledPagination } from "./PaginationStyled";
import { PaginationProps } from "./PaginationTypes";

const Pagination = ({ page, pageCount, onChange }: PaginationProps) => {
	return (
		<StyledPagination
			disableInitialCallback
			forcePage={page - 1}
			onPageChange={({ selected }) => {
				onChange(selected + 1);
			}}
			nextLabel='Далее'
			previousLabel='Назад'
			pageCount={pageCount}
			pageRangeDisplayed={4}
			marginPagesDisplayed={1}
		/>
	);
};

export default Pagination;
