import styled from "styled-components";
import Flex from "../Flex";
import InputContained from "../InputContained";
import Svg from "../Svg";

export const StyledHeaderSearch = styled(Flex)`
  position: relative;
  margin-right: auto;
  padding-left: 30px;
  border-left: 3px solid white;

  ${InputContained} {
    background-color: white;
    color: black;
  }

  ${Svg} {
    margin-left: 16px;
  }
`;
