import React from "react";
import styled from "styled-components";
import InputSecond from "../InputSecond";
import Paper from "../Paper";
import Text from "../Text";
import { UserScoreProps } from "./interface";

const Container = styled(Paper).attrs({ backgroundColor: "white" })`
  display: inline-flex;
  flex-direction: column;

  > div {
    display: inline-flex;
    align-items: center;

    & + div {
      margin-top: 18px;
    }
  }

  ${Text} {
    width: 90px;
    margin-right: 38px;
  }
`;

const ScoresValueInput = styled(InputSecond).attrs({ readOnly: true })`
  min-width: 104px;
  background-color: #549d61;
  border-radius: 14px;
  color: #ccff33;
  font-weight: 400;
  font-size: 24px;
  padding: 2px 0;
`;

const UserScores = (props: UserScoreProps) => {
	const { rating, scores, ...restProps } = props;

	return (
		<Container {...restProps}>
			<div>
				<Text color='#666666' size={24} weight={300}>
					баллы
				</Text>
				<ScoresValueInput value={scores} size={1} />
			</div>
			<div>
				<Text color='#666666' size={24} weight={300}>
					рейтинг
				</Text>
				<ScoresValueInput value={rating} size={1} />
			</div>
		</Container>
	);
};

export default styled(UserScores)({});
