import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import chevronIcon from "./chevron.svg";
import { SelectChevronProps } from "./interface";

const SelectChevronContainer = styled.div<{ isActive?: boolean }>`
  display: inline-flex;
  background-color: ${getTheme("grayKraiola")};
  width: 36px;
  height: 29px;

  svg {
    transform: rotate(${(props) => (props.isActive ? "180deg" : 0)});
  }
`;

const SelectChevron = ({ isActive }: SelectChevronProps) => {
	return (
		<SelectChevronContainer isActive={isActive}>
			<ReactSVG src={chevronIcon} />
		</SelectChevronContainer>
	);
};

export default SelectChevron;
