import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import usersReducer from "./usersSlice";
import tasksReducer from "./tasksSlice";
import chaptersReducer from "./chaptersSlice";
import aboutReducer from "./aboutSlice";
import dictionaryReducer from "./dictionarySlice";

export const store = configureStore({
	reducer: {
		user: userReducer,
		users: usersReducer,
		chapter: chaptersReducer,
		tasks: tasksReducer,
		about: aboutReducer,
		dictionary: dictionaryReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
