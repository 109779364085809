import React from "react";
import { TaskTypes } from "../../types/tasks";
import DividerRed from "../DividerRed";
import Flex from "../Flex";
import TaskAnswerVariants from "../TaskAnswerVariants";
import TaskTextEditor from "../TaskTextEditor";

const TaskCorrectTranslateForm = () => {
	return (
		<Flex direction='column'>
			<TaskTextEditor />
			<DividerRed />
			<TaskAnswerVariants type={TaskTypes.correctTranslate} />
			<DividerRed />
		</Flex>
	);
};

export default TaskCorrectTranslateForm;
