import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { RootState } from "../../redux/store";
import { deleteTask } from "../../redux/tasksSlice";
import { Task } from "../../types/tasks";
import BinIcon from "../BinIcon";
import Svg from "../Svg";
import { Table, TableContainer, TableHead, TableHeadCell } from "../Table";
import TaskLevel from "../TaskLevel";
import TaskVariantIcon from "../TaskVariantIcon";
import Text from "../Text";
import penIcon from "../../img/pen.svg";
import { TasksTableProps } from "./interface";

const BrownCell = styled.td<{ isRightEdge?: boolean }>`
  padding: 0;

  > div {
    display: inline-flex;
    width: 100%;
    height: 38px;
    padding: 8px 0;
    background-color: #a78772;

    > div,
    span {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
    }
  }

  & + & {
    > div {
      > div {
        border-left: 1px solid white;
      }
    }
  }

  ${({ isRightEdge }) => {
		if (isRightEdge) {
			return css`
        > div {
          border-radius: 0px 7px 7px 0;
        }
      `;
		}
	}};
`;

const TableIcon = styled(Svg).attrs({ src: penIcon, color: "white" })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #eb5757;
  border-radius: 8px;
  width: 49px;
  height: 38px;
  padding: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledBinIcon = styled(BinIcon).attrs({ color: "white" })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width: 49px;
  padding: 8px;
  background-color: #eb5757;
  border-radius: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledTableBody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;

  td {
    text-align: center;
  }
`;

const TasksTable = ({ updateTasks }: TasksTableProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { tasks } = useSelector((state: RootState) => state.tasks);

	const createIconHandler = useCallback(
		(type: "edit" | "delete", task: Task) => async () => {
			if (type === "edit") {
				history.push(`/tasks/${task._id}?type=${task.type}`);
			} else if (type === "delete") {
				await dispatch(deleteTask(task._id));
				updateTasks();
			}
		},
		[dispatch, history, updateTasks],
	);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<tr>
						<TableHeadCell>№</TableHeadCell>
						<TableHeadCell>
							вариант
							<br />
							вопроса
						</TableHeadCell>
						<TableHeadCell>баллы</TableHeadCell>
						<TableHeadCell>уровень</TableHeadCell>
						<TableHeadCell>статус</TableHeadCell>
						<TableHeadCell>ред.</TableHeadCell>
						<TableHeadCell>удалить</TableHeadCell>
					</tr>
				</TableHead>
				<StyledTableBody>
					{tasks.map((task) => (
						<tr key={task._id}>
							<td>
								<Text color="#a78772" weight={700} size={18}>
									{task.number}
								</Text>
							</td>
							<td>
								<TaskVariantIcon type={task.type} />
							</td>
							<BrownCell>
								<div>
									<Text color="#CCFF33" weight={500} size={18}>
										{task.points}
									</Text>
								</div>
							</BrownCell>
							<BrownCell>
								<div>
									<div>
										{task.level === "test" ? (
											<Text color="white" size={18}>
												Тест
											</Text>
										) : (
											<TaskLevel level={task.level} />
										)}
									</div>
								</div>
							</BrownCell>
							<BrownCell isRightEdge>
								<div>
									<Text color="white" size={18}>
										{task.active ? "открытый" : "закрытый"}
									</Text>
								</div>
							</BrownCell>
							<td>
								<div>
									<TableIcon onClick={createIconHandler("edit", task)} />
								</div>
							</td>
							<td>
								<div>
									<StyledBinIcon onClick={createIconHandler("delete", task)} />
								</div>
							</td>
						</tr>
					))}
				</StyledTableBody>
			</Table>
		</TableContainer>
	);
};

export default TasksTable;
