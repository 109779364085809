import styled from "styled-components";
import { DividerProps } from "./interface";

const Divider = styled.hr<DividerProps>`
  width: 100%;
  border-color: ${(props) => props.color || props.theme.black};
  border-style: solid;
`;

export default Divider;
