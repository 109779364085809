import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AboutPageSidebar from "../../components/AboutPageSidebar";
import ButtonBlue from "../../components/ButtonBlue";
import DividerRed from "../../components/DividerRed";
import FormGroup from "../../components/FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../../components/FormGroup/interface";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import ImageUpload from "../../components/ImageUpload";
import Page from "../../components/Page";
import {
	getAboutGrammarAsync,
	updateGrammarAsync,
} from "../../redux/aboutSlice";
import { RootState } from "../../redux/store";

const AboutGrammarPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAboutGrammarAsync());
	}, [dispatch]);

	const storeFilename = useSelector((state: RootState) => state.about.grammar);

	const [filename, setFilename] = useState("");

	useEffect(() => {
		setFilename(storeFilename);
	}, [storeFilename]);

	const save = async () => {
		try {
			await dispatch(updateGrammarAsync(filename));

			alert("Изменения успешно сохранены");
		} catch (e) {
			alert("Ошибка при сохранении изменений");
		}
	};

	return (
		<Page sidebar={<AboutPageSidebar />}>
			<GoBackButton
				to='/'
				label='вернуться на главную'
				backgroundColor='#86C6A7'
			/>
			<FormGroup
				labelPlacement={FormGroupLabelPlacements.top}
				label='Грамматика'
				margin={FormGroupMargins.normal}
			>
				<ImageUpload type='doc' onChange={setFilename} file={filename} />
			</FormGroup>
			<DividerRed />
			<ButtonBlue onClick={save}>Сохранить</ButtonBlue>
		</Page>
	);
};

export default AboutGrammarPage;
