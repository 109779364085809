import React from "react";
import styled from "styled-components";
import Flex from "../Flex";
import Text from "../Text";
import { TableHeadCellProps } from "./inderface";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  th {
    top: 0;
    position: sticky;
    padding: 0 0 12px;

    div {
      height: 48px;
      background-color: #828282;
    }
  }
`;

export const TableHeadCell = ({
	children,
	align = "center",
	style,
}: TableHeadCellProps) => {
	const justify = align === "left" ? "flex-start" : "center";

	return (
		<th>
			<Flex style={style} alignItems='center' justify={justify}>
				<Text size={14} weight={300} color='white'>
					{children}
				</Text>
			</Flex>
		</th>
	);
};

export const TableContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;
