import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { debounce } from "throttle-debounce";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import InputContained from "../InputContained";
import useRoutes from "../Router/useRoutes";
import Svg from "../Svg";
import { HeaderSearchProps } from "./HeaderSearchTypes";
import { StyledHeaderSearch } from "./HeaderSearchStyled";
import searchIcon from "../../img/search.svg";

const HeaderSearch = (props: HeaderSearchProps) => {
	const { currentRoute } = useRoutes();

	const [value, setValue] = useState("");

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		page: NumberParam,
	});

	useEffect(() => {
		if (query.search) {
			setValue(query.search);
		}
	}, [query.search]);

	const debouncedSetQuerySearch = useMemo(
		() =>
			debounce(500, (value: string) => {
				setQuery({ search: value || undefined, page: undefined });
			}),
		[setQuery],
	);

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			setValue(value);
			debouncedSetQuerySearch(value);
		},
		[debouncedSetQuerySearch],
	);

	if (currentRoute.id !== "vocabulary") {
		return null;
	}

	return (
		<StyledHeaderSearch alignItems='center'>
			<InputContained value={value} onChange={handleChange} />
			<Svg src={searchIcon} size={40} color='white' />
		</StyledHeaderSearch>
	);
};

export default HeaderSearch;
