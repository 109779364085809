import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const StyledPagination = styled(ReactPaginate)`
  font-family: sans-serif, Roboto;
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0;

  li {
    display: inline-flex;
    padding: 0 6px;

    a {
      padding: 10px 15px;
      border-radius: 4px;
      color: black;
      cursor: pointer;
      user-select: none;
    }

    &.selected {
      a {
        background-color: #a78772;
        color: white;
      }
    }
  }
`;
