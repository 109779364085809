import React from "react";
import styled from "styled-components";
import Flex from "../Flex";
import Paper from "../Paper";
import Text from "../Text";
import UserTasksInfoLevel from "../UserTasksInfoLevel";

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
`;

const UserTasksInfo = () => {
	return (
		<Container backgroundColor='white'>
			<Text color='#666666' size={24} weight={300}>
				выполнено заданий
			</Text>
			<Flex direction='column'>
				<UserTasksInfoLevel level={0} value={0} />
				<UserTasksInfoLevel level={1} value={0} />
				<UserTasksInfoLevel level={2} value={0} />
			</Flex>
		</Container>
	);
};

export default UserTasksInfo;
