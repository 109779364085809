import React from "react";
import styled from "styled-components";
import InputSecond from "../InputSecond";
import SidebarBlock from "../SidebarBlock";
import Text from "../Text";
import { UsersPageSidebarProps } from "./interface";

const Container = styled.div`
  margin-right: 48px;
  padding: 0 8px;
  background-color: rgba(33, 150, 83, 0.5);

  ${SidebarBlock} {
    align-items: center;
    padding: 28px 34px;

    ${InputSecond} {
      max-width: 120px;
      margin: 14px 0;
    }
  }
`;

const UsersPageSidebar = ({ usersCount }: UsersPageSidebarProps) => {
	return (
		<Container>
			<SidebarBlock>
				<Text weight={700} size={24} color='white' textTransform='uppercase'>
					пользователи
				</Text>
				<InputSecond readOnly value={usersCount} />
				<Text size={18} color='white'>
					кол-во пользователей
				</Text>
			</SidebarBlock>
		</Container>
	);
};

export default UsersPageSidebar;
