const getLocalStorage = (key: string) => {
	try {
		const storage = localStorage.getItem(key);
		if (!storage) return;
		return JSON.parse(storage);
	} catch (_) {
		return;
	}
};

export default getLocalStorage;
