import styled from "styled-components";
import { TextProps } from "./interface";

const Text = styled.span<TextProps>`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: ${(props) =>
		typeof props.size === "number" ? props.size + "px" : props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  text-align: ${(props) => props.align};
  line-height: ${(props) => props.lineHeight};
  text-transform: ${(props) => props.textTransform};
  text-decoration: ${(props) => props.textDecoration};
`;

export default Text;
