import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { setTaskParams } from "../../redux/tasksSlice";
import { TaskSix, TaskTypes } from "../../types/tasks";

import VideoUpload from "../VideoUpload";
import DividerRed from "../DividerRed";
import Flex from "../Flex";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import FormInput from "../FormInput";
import TaskAnswerVariants from "../TaskAnswerVariants";
import TaskTextEditor from "../TaskTextEditor";

import { TaskVideoFormProps } from "./interface";

const TaskVideoForm = ({ isFreeAnswer }: TaskVideoFormProps) => {
	const dispatch = useDispatch();

	const video = useSelector(
		(state: RootState) => (state.tasks.task?.params as TaskSix["params"]).video,
	);

	const answer = useSelector(
		(state: RootState) =>
			(state.tasks.task?.params as TaskSix["params"]).answerArray,
	);

	const handleChangeVideo = useCallback(
		(nextVideo: string) => {
			dispatch(setTaskParams({ video: nextVideo }));
		},
		[dispatch],
	);

	return (
		<Flex direction='column'>
			<TaskTextEditor />

			<DividerRed />

			<FormGroup
				margin={FormGroupMargins.normal}
				label='прикрепить видео (файл не более 1 Мбайт)'
				labelPlacement={FormGroupLabelPlacements.top}
			>
				<Flex justify='center'>
					<VideoUpload src={video} onChange={handleChangeVideo} />
				</Flex>
			</FormGroup>

			<DividerRed />

			<TaskAnswerVariants
				type={isFreeAnswer ? TaskTypes.audioFreeAnswer : TaskTypes.audio}
				isFreeAnswer={isFreeAnswer}
			/>

			{!isFreeAnswer && (
				<FormGroup
					margin={FormGroupMargins.normal}
					label='Ответ'
					labelPlacement={FormGroupLabelPlacements.top}
				>
					<FormInput value={(answer as string[]).join(" ")} readOnly />
				</FormGroup>
			)}
		</Flex>
	);
};

export default TaskVideoForm;
