import React from "react";
import Svg from "../Svg";
import Text from "../Text";
import { EditButtonProps } from "./interface";
import penIcon from "../../img/pen.svg";
import { StyledEditButton } from "./EditButtonStyled";

const EditButton = ({
	children,
	component = "button",
	...restProps
}: EditButtonProps) => {
	return (
		<StyledEditButton as={component} {...restProps}>
			<Text weight={700} size={24} color='white'>
				{children}
			</Text>
			<Svg src={penIcon} />
		</StyledEditButton>
	);
};

export default EditButton;
