import request, { gql } from "graphql-request";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Page from "../../components/Page";
import { setUsers } from "../../redux/usersSlice";
import UsersPageSidebar from "../../components/UsersPageSidebar";
import UsersTable from "../../components/UsersTable";

const UsersPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		request(
			"/api/admin",
			gql`
        query {
          users(pagination: { limit: 9999 }) {
            _id
            phone
            createdAt
            firstName
            lastName
            email
          }
          usersCount
        }
      `,
		).then((response) => {
			dispatch(setUsers(response));
		});
	}, [dispatch]);

	const { items: users, count } = useSelector(
		(state: RootState) => state.users,
	);

	return (
		<Page
			sidebar={<UsersPageSidebar usersCount={count} />}
			isMirroredBackground
		>
			<UsersTable users={users} />
		</Page>
	);
};

export default UsersPage;
