import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Paper from "../Paper";
import Svg from "../Svg";
import Text from "../Text";
import { GoBackButtonProps } from "./interface";
import leftArrowIcon from "./leftArrow.svg";

export const GoBackButtonContainer = styled(Paper)`
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  min-width: 415px;
  cursor: pointer;
  user-select: none;

  ${Text} {
    margin-left: 28px;
  }
`;

const iconSize = [66, 22];

const GoBackButton = (props: GoBackButtonProps) => {
	const { backgroundColor, label = "назад", to, ...restProps } = props;

	const history = useHistory();

	const handleClick = useCallback(() => {
		history.push(to);
	}, [history, to]);

	return (
		<GoBackButtonContainer
			{...restProps}
			backgroundColor={backgroundColor}
			onClick={handleClick}
		>
			<Svg src={leftArrowIcon} size={iconSize} color='white' />
			<Text weight={400} size={24} color='white'>
				{label}
			</Text>
		</GoBackButtonContainer>
	);
};

export default styled(GoBackButton)({});
