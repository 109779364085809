import styled from "styled-components";
import Button from "../Button";
import Input from "../Input";

const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 336px;
  margin: 116px auto 0;

  ${Input} {
    & + ${Input} {
      margin-top: 59px;
    }
  }

  ${Button} {
    margin-top: 15px;
  }
`;

export default AuthForm;
