import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { TaskTypes, TaskNine } from "../../types/tasks";
import useTaskHandlers from "../../utils/useTaskHandlers";

import DividerRed from "../DividerRed";
import Flex from "../Flex";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import FormInput from "../FormInput";
import TaskAnswerVariants from "../TaskAnswerVariants";
import TaskTextEditor from "../TaskTextEditor";

import { TaskWordByLettersFormProps } from "./interface";

const TaskWordByLettersForm: React.FC<TaskWordByLettersFormProps> = () => {
	// const answer = useSelector(
	// 	(state: RootState) =>
	// 		(state.tasks.task!.params as TaskThree["params"]).answerArray,
	// );

	const { createInputParamsHandler } = useTaskHandlers<TaskTypes.images>();

	const answer = useSelector((state: RootState) => {
		const params = state.tasks.task?.params as TaskNine["params"] | undefined;

		if (params) {
			return params.answer;
		}

		return "";
	});

	return (
		<Flex direction="column">
			<TaskTextEditor />

			<DividerRed />

			<FormGroup
				margin={FormGroupMargins.normal}
				label="Ответ"
				labelPlacement={FormGroupLabelPlacements.top}
			>
				<FormInput
					value={answer}
					onChange={createInputParamsHandler("answer")}
				/>
			</FormGroup>
		</Flex>
	);
};

export default TaskWordByLettersForm;
