import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import { ButtonProps } from "./interface";

const Button = styled.button<ButtonProps>`
  width: ${(props) => props.fullWidth && "100%"};
  height: 52px;
  background-color: ${getTheme("slateBlue")};
  font-family: ${getTheme("fontFamily")};
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
`;

export default Button;
