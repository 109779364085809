import { createSlice } from "@reduxjs/toolkit";
import { gql } from "graphql-request";
import client from "../utils/request";
import { AppThunk } from "./store";

export interface AboutAuthorState {
	photos: string[];
	description: string;
}

export interface AboutProjectState {
	photos: string[];
	description: string;
}

export interface AboutState {
	author: AboutAuthorState;
	project: AboutProjectState;
	banner: {
		src: string
		link: string
	};
	grammar: string;
}

const initialState: AboutState = {
	author: {
		photos: [],
		description: "",
	},
	project: {
		photos: [],
		description: "",
	},
	banner: {
		src: "",
		link: "",
	},
	grammar: "",
};

const aboutSlice = createSlice({
	name: "about",
	initialState,
	reducers: {
		setAbout: (state, action) => {
			state.author = action.payload.author;
			state.project = action.payload.project;
			state.banner = action.payload.banner;
		},
		setGrammar: (state, action) => {
			state.grammar = action.payload;
		},
	},
});

export const { setAbout, setGrammar } = aboutSlice.actions;

export const getAboutAsync = (): AppThunk => async (dispatch) => {
	try {
		const response = await client.request(
			gql`
        query {
          aboutProject {
            author {
              description
              photos
            }
            project {
              description
              photos
            }
            banner {
							src
							link
						}
          }
        }
      `,
		);

		dispatch(setAbout(response.aboutProject));
	} catch (e) { }
};

export const getAboutGrammarAsync = (): AppThunk => async (dispatch) => {
	try {
		const response = await client.request(
			gql`
        query {
          grammar {
            data {
              filename
            }
          }
        }
      `,
		);

		dispatch(setGrammar(response.grammar.data.filename));
	} catch (e) { }
};

export const updateAboutAppAsync =
	(photos: string[], description: string): AppThunk =>
		async () => {
			try {
				await client.request(
					gql`
        mutation UpdateAboutProject($photos: [String]!, $description: String!) {
          updateAboutProject(
            aboutProject: { photos: $photos, description: $description }
          )
        }
      `,
					{ photos, description },
				);
			} catch (e) { }
		};

export const updateAboutAuthorAsync =
	(photos: string[], description: string): AppThunk =>
		async () => {
			try {
				await client.request(
					gql`
        mutation UpdateAboutAuthor($photos: [String]!, $description: String!) {
          updateAboutProject(
            aboutAuthor: { photos: $photos, description: $description }
          )
        }
      `,
					{ photos, description },
				);
			} catch (e) { }
		};

export const updateBannerAsync =
	(banner: string, link: string): AppThunk =>
		async () => {
			try {
				await client.request(
					gql`
        mutation UpdateBanner($banner: String!, $link: String!) {
          updateAboutProject(banner: { src: $banner, link: $link })
        }
      `,
					{ banner, link },
				);
			} catch (e) { }
		};

export const updateGrammarAsync =
	(filename: string): AppThunk =>
		async () => {
			try {
				await client.request(
					gql`
        mutation UpdateGrammar($filename: String!) {
          editGrammar(filename: $filename)
        }
      `,
					{ filename },
				);
			} catch (e) { }
		};

export default aboutSlice.reducer;
