import styled from "styled-components";
import { BackgroundProps } from "./interface";
import bg from "./bg.png";
import bg2 from "./bg2.png";

const Background = styled.div<BackgroundProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  
  &:after {
    content: '';
    display: block;
    position: fixed;
    left: 50%;
    transform: translateX(-50%) ${(props) => props.isMirrored && "scale(-1, 1)"};
    height: 100%;
    width: 1150px;
    background-image: url(${bg2});
    background-size: 100%;
    background-position: center;
  }
`;

export default Background;
