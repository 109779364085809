import { ReactNode } from "react";

export enum FormGroupMargins {
	normal,
}

export enum FormGroupLabelPlacements {
	left,
	top,
}

export interface FormGroupProps {
	label?: string;
	children: ReactNode;
	margin?: FormGroupMargins;
	labelPlacement?: FormGroupLabelPlacements;
	className?: string;
}
