import React from "react";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import Svg from "../Svg";
import Text from "../Text";
import { RefreshButtonProps } from "./interface";
import refreshIcon from "./refresh.svg";

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${Svg} {
    width: 60px;
    height: 60px;
    background-color: ${getTheme("darkBlue")};
    border-radius: 50%;
    margin-bottom: 4px;
  }
`;

const RefreshButton = ({ onClick, ...restProps }: RefreshButtonProps) => {
	return (
		<Container {...restProps} onClick={onClick}>
			<Svg src={refreshIcon} color='white' />
			<Text weight={500} size={18} color='#1B53DB' align='center'>
				обновить
				<br />
				данные
			</Text>
		</Container>
	);
};

export default RefreshButton;
