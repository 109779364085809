import { TaskTypes } from "../../types/tasks";

import DividerRed from "../DividerRed";
import Flex from "../Flex";
import TaskAnswerPairs from "../TaskAnswerPairs";
import TaskTextEditor from "../TaskTextEditor";

const TaskCorrectTranslateForm = () => {
	return (
		<Flex direction='column'>
			<TaskTextEditor />
			<DividerRed />
			<TaskAnswerPairs type={TaskTypes.pairs} />
			<DividerRed />
		</Flex>
	);
};

export default TaskCorrectTranslateForm;
