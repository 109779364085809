import React, { useCallback, useContext, useEffect, useMemo } from "react";
import SelectContext from "../SelectContext";
import SelectOptionContainer from "../SelectOptionContainer";
import { SelectOptionProps } from "./interface";

const SelectOption = (props: SelectOptionProps) => {
	const { value, children } = props;

	const context = useContext(SelectContext);
	const { setLabel, onChange, setIsOpen } = context;

	useEffect(() => {
		if (context.value === value) {
			setLabel(children);
			onChange(value);
		}
	}, [context.value, value, setLabel, onChange, children]);

	const isActive = useMemo(
		() => value === context.value,
		[value, context.value],
	);

	const handleClick = useCallback(() => {
		setLabel(children);
		onChange(value);
		setIsOpen(false);
	}, [setLabel, onChange, children, value, setIsOpen]);

	return (
		<SelectOptionContainer isActive={isActive} onClick={handleClick}>
			{children}
		</SelectOptionContainer>
	);
};

export default SelectOption;
