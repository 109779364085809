import React, { ChangeEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import { setTaskParams } from "../../redux/tasksSlice";
import {
	TaskFive,
	TaskFour,
	TaskThree,
	TaskTwo,
	TaskTypes,
} from "../../types/tasks";
import Checkbox from "../Checkbox";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import FormInput from "../FormInput";
import Text from "../Text";
import { TaskAnswerVariantsProps } from "./interface";

const InputContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: calc(100% / 2 - 20px);
  margin: 12px 10px;

  ${FormInput} {
    width: 100%;
    margin-right: 9px;
  }
`;

const AnswerOrder = styled(Text)`
  position: absolute;
  left: -12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px -10px;
`;

const TaskAnswerVariants: React.FC<TaskAnswerVariantsProps> = ({
	isFreeAnswer,
	type,
	answersCount = 10,
}) => {
	const dispatch = useDispatch();

	const answers = useSelector((state: RootState) => {
		const params = state.tasks.task?.params as
			| TaskTwo["params"]
			| TaskThree["params"]
			| TaskFive["params"]
			| undefined;

		if (params) {
			return params.answers;
		}

		return [];
	});

	const answer = useSelector((state: RootState) =>
		type === TaskTypes.audio
			? (state.tasks.task?.params as TaskThree["params"]).answerArray || ""
			: (state.tasks.task?.params as TaskFour["params"]).answer,
	);

	const createInputHandler = useCallback(
		(index: number) => (event: ChangeEvent<HTMLInputElement>) => {
			let nextAnswers = answers.slice();
			nextAnswers[index] = event.target.value;
			nextAnswers = nextAnswers.map((item) => item || "");
			dispatch(setTaskParams({ answers: nextAnswers }));
		},
		[dispatch, answers],
	);

	const createCheckboxHandler = useCallback(
		(index: number) => () => {
			if (!answers[index]) {
				return;
			}

			if (type === TaskTypes.audio) {
				const nextAnswer = answer.slice() as string[];

				const matchIndex = nextAnswer.indexOf(answers[index]);

				if (matchIndex !== -1) {
					nextAnswer.splice(matchIndex, 1);
				} else {
					nextAnswer.push(answers[index]);
				}

				dispatch(setTaskParams({ answerArray: nextAnswer }));
			} else {
				dispatch(setTaskParams({ answer: answers[index] }));
			}
		},
		[dispatch, answers, answer, type],
	);

	const checkAnswer = useCallback(
		(index: number) => {
			if (type === TaskTypes.audio) {
				return Boolean(answer?.includes(answers[index]));
			}

			return Boolean(answer && answer === answers[index]);
		},
		[answers, answer, type],
	);

	const handleChangeAnswer = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			dispatch(setTaskParams({ answer: event.target.value }));
		},
		[dispatch],
	);

	return (
		<FormGroup
			margin={FormGroupMargins.normal}
			label={isFreeAnswer ? "ответ" : "варианты ответа"}
			labelPlacement={FormGroupLabelPlacements.top}
		>
			<Wrapper>
				{isFreeAnswer ? (
					<InputContainer>
						<FormInput value={answer} onChange={handleChangeAnswer} />
					</InputContainer>
				) : (
					[...new Array(answersCount)].map((_, idx) => idx).map((index) => (
						<InputContainer key={index}>
							{type === TaskTypes.audio && checkAnswer(index) && (
								<AnswerOrder>{answer.indexOf(answers[index]) + 1}</AnswerOrder>
							)}
							<FormInput
								value={answers[index] || ""}
								onChange={createInputHandler(index)}
							/>
							<Checkbox
								isChecked={checkAnswer(index)}
								onChange={createCheckboxHandler(index)}
							/>
						</InputContainer>
					))
				)}
			</Wrapper>
		</FormGroup>
	);
};

export default TaskAnswerVariants;
