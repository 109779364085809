import styled from "styled-components";

import getTheme from "../../utils/getTheme";

const ParamButton = styled.button<{ isActive?: boolean }>`
  height: 32px;
  padding: 0 16px;
  background-color: ${({ isActive }) =>
		getTheme(isActive ? "darkGray" : "darkGray2")};
  font-family: ${getTheme("fontFamily")};
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
`;

export default ParamButton;
