import React from "react";
import styled from "styled-components";
import AddIcon from "../AddIcon";

const TaskPageTitleContainer = styled.div`
  display: inline-flex;
  align-items: center;

  ${AddIcon} {
    margin-right: 13px;
  }
`;

const TaskPageTitle = () => {
	return (
		<TaskPageTitleContainer>
			<AddIcon />
			<span>Добавить задание</span>
		</TaskPageTitleContainer>
	);
};

export default TaskPageTitle;
