import React, { FormEvent } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSlice";
import AuthForm from "../../components/AuthForm";
import Button from "../../components/Button";
import Flex from "../../components/Flex";
import Input from "../../components/Input";
import Page from "../../components/Page";
import Title from "../../components/Title";

const AuthPage = () => {
	const dispatch = useDispatch();

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = Object.fromEntries(
			new FormData(event.currentTarget).entries(),
		) as { login: string; password: string };

		dispatch(login(formData.login, formData.password));
	};

	return (
		<Page>
			<Flex justify='center'>
				<Title>Авторизация</Title>
			</Flex>
			<AuthForm onSubmit={handleSubmit}>
				<Input name='login' placeholder='Логин' />
				<Input name='password' placeholder='Пароль' type='password' />
				<Button>ВОЙТИ</Button>
			</AuthForm>
		</Page>
	);
};

export default AuthPage;
