import { useMemo } from "react";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import ButtonAdd, { ButtonAddContainer } from "../ButtonAdd";
import useRoutes from "../Router/useRoutes";
import SidebarBlock from "../SidebarBlock";
import Text from "../Text";

const Container = styled.div`
  max-width: 267px;
  margin-right: 32px;
  padding: 16px 8px;
  background-color: ${getTheme("redOpacity75")};

  ${SidebarBlock} {
    padding: 14px 6px;

    &:nth-child(2) {
      a {
        text-decoration: none;
        color: ${getTheme("white")};

        &.active {
          color: ${getTheme("salad")};
        }

        & + a {
          margin-top: 16px;
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  ${ButtonAddContainer} {
    white-space: nowrap;
  }
`;

const ChaptersPageSidebar = () => {
	const { currentRoute } = useRoutes();

	const buttonAddIsActive = useMemo(() => {
		return currentRoute.id === "chapter";
	}, [currentRoute.id]);

	return (
		<Container>
			<SidebarBlock>
				<Text color='white' weight={700} size={24} textTransform='uppercase'>
					Главы
				</Text>
			</SidebarBlock>

			<SidebarBlock>
				<ButtonAdd isLink to='/chapters/new' $isActive={buttonAddIsActive}>
					добавить главу
				</ButtonAdd>
			</SidebarBlock>
		</Container>
	);
};

export default ChaptersPageSidebar;
