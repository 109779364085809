import { rgba } from "polished";

const darkGray = "#828282";
const red = "#EB5757";
const lightGrayBlue = "#86C6A7";

const theme = {
	fontFamily: "Roboto, sans-serif",
	white: "#ffffff",
	black: "#000000",
	dullGray: "#666666",
	darkGray,
	darkGray2: "#b3b3b3",
	darkGrayAlpha7: rgba(darkGray, 0.7),
	slateBlue: "#565bdf",
	chestnut: "#DF5656",
	copperPink: "#996666",
	salad: "#ccff33",
	darkBlue: "#1b53db",
	orange: "#F2994A",
	red,
	redOpacity75: rgba(red, 0.75),
	lightGrayBlue,
	gray: "#E5E5E5",
	darkGreen: "#53773B",
	grayKraiola: "#A39189",
	green: "#219653",
	selectZIndex: 100,
};

export type Theme = typeof theme;

export default theme;
