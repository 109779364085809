import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import Input from "../Input";

const InputContained = styled(Input)`
  padding: 8px 12px;
  background: ${getTheme("darkGrayAlpha7")};
  border-radius: 7px;
  border-bottom: none;
  color: white;
`;

export default InputContained;
