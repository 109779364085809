import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import Button from "../Button";

const ButtonOrange = styled(Button)`
  height: 41px;
  background-color: ${getTheme("orange")};
  padding: 0 40px;
`;

export default ButtonOrange;
