import styled from "styled-components";
import getTheme from "../../utils/getTheme";

const FormInput = styled.input`
  padding: 6px 14px;
  font: 400 16px Roboto;
  color: ${getTheme("black")};
  background-color: ${getTheme("white")};
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
`;

export default FormInput;
