import { ChangeEvent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "../../redux/store";
import { setTaskParams } from "../../redux/tasksSlice";
import {
	TaskFive,
	TaskFour,
	TaskThree,
	TaskTwo,
	TaskTypes,
} from "../../types/tasks";

import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import FormInput from "../FormInput";
import Text from "../Text";

import { TaskAnswerPairs } from "./interface";

const InputContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: calc(100% / 2 - 20px);
  margin: 12px 10px;

  ${FormInput} {
    width: 100%;
    margin-right: 9px;
  }
`;

const AnswerOrder = styled(Text)`
  position: absolute;
  left: -12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px -10px;
`;

const TaskAnswerVariants: React.FC<TaskAnswerPairs> = ({ type }) => {
	const dispatch = useDispatch();

	const answers = useSelector((state: RootState) => {
		const params = state.tasks.task?.params as
			| TaskTwo["params"]
			| TaskThree["params"]
			| TaskFive["params"]
			| undefined;

		if (params) {
			return params.answers;
		}

		return [];
	});

	const answer = useSelector((state: RootState) =>
		type === TaskTypes.audio
			? (state.tasks.task?.params as TaskThree["params"]).answerArray || ""
			: (state.tasks.task?.params as TaskFour["params"]).answer,
	);

	const createInputHandler = useCallback(
		(index: number) => (event: ChangeEvent<HTMLInputElement>) => {
			let nextAnswers = answers.slice();
			nextAnswers[index] = event.target.value;
			nextAnswers = nextAnswers.map((item) => item || "");
			dispatch(setTaskParams({ answers: nextAnswers }));
		},
		[dispatch, answers],
	);

	const checkAnswer = useCallback(
		(index: number) => {
			if (type === TaskTypes.audio) {
				return Boolean(answer?.includes(answers[index]));
			}

			return Boolean(answer && answer === answers[index]);
		},
		[answers, answer, type],
	);

	return (
		<FormGroup
			label="Пары слов"
			margin={FormGroupMargins.normal}
			labelPlacement={FormGroupLabelPlacements.top}
		>
			<Wrapper>
				{[...new Array(12)]
					.map((_, idx) => idx)
					.map((index) => (
						<InputContainer key={index}>
							{type === TaskTypes.audio && checkAnswer(index) && (
								<AnswerOrder>{answer.indexOf(answers[index]) + 1}</AnswerOrder>
							)}
							<FormInput
								value={answers[index] || ""}
								onChange={createInputHandler(index)}
							/>
						</InputContainer>
					))}
			</Wrapper>
		</FormGroup>
	);
};

export default TaskAnswerVariants;
