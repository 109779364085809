import { ReactSVG } from "react-svg";
import styled, { css } from "styled-components";
import { SvgProps } from "./interface";

const Svg = styled(ReactSVG)<SvgProps>`
  flex: none;
  display: inline-flex;
  color: ${(props) => props.color};
  ${(props) => {
		let width = props.size;
		let height = props.size;

		if (Array.isArray(props.size)) {
			width = props.size[0];
			height = props.size[1];
		}

		return css`
      width: ${typeof width === "number" ? width + "px" : width};
      height: ${typeof height === "number" ? height + "px" : height};
    `;
	}};

  div,
  svg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  ${(props) => {
		if (!props.isInitialColor) {
			return css`
        svg {
          fill: currentColor;

          * {
            fill: inherit;
          }
        }
      `;
		}
	}};
`;

export default Svg;
