import styled from "styled-components";
import getTheme from "../../utils/getTheme";

const Input = styled.input`
  border: none;
  outline: none;
  padding-bottom: 10px;
  border-bottom: 3px solid ${getTheme("chestnut")};
  background-color: transparent;
  font-family: ${getTheme("fontFamily")};
  font-size: 24px;
  line-height: 28px;
  font-weight: 300;

  ::placeholder {
    color: ${getTheme("darkGray")};
  }
`;

export default Input;
