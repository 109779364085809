import React, { useCallback, useMemo } from "react";
import Svg from "../Svg";
import Text from "../Text";
import { LanguageSwitcherProps } from "./LanguageSwitcherTypes";
import { StyledLanguageSwitcher } from "./LanguageSwitcherStyled";
import switcherIcon from "../../img/switcher.svg";

const LanguageSwitcher = ({
	isSourceRus,
	onChange,
	isSmall,
	className,
}: LanguageSwitcherProps) => {
	const handleClick = useCallback(() => {
		if (onChange) {
			onChange();
		}
	}, [onChange]);

	const iconSize = useMemo(() => {
		if (isSmall) {
			return [24, 14];
		}

		return [46, 26];
	}, [isSmall]);

	return (
		<StyledLanguageSwitcher className={className} onClick={handleClick}>
			<Text
				color={isSmall ? "#828282" : "white"}
				size={isSmall ? 12 : 24}
				weight={isSmall ? 400 : 700}
			>
				<span>{isSourceRus ? "РУC" : "ИНГ"}</span>
				<Svg size={iconSize} src={switcherIcon} />
				<span>{isSourceRus ? "ИНГ" : "РУС"}</span>
			</Text>
		</StyledLanguageSwitcher>
	);
};

export default LanguageSwitcher;
