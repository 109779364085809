import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import {
	NumberParam,
	StringParam,
	useQueryParams,
	withDefault,
} from "use-query-params";
import InfiniteScroll from "react-infinite-scroller";
import BinIcon from "../../components/BinIcon";
import DictionarySidebar from "../../components/DictionarySidebar";
import Page from "../../components/Page";
import Svg from "../../components/Svg";
import {
	Table,
	TableContainer,
	TableHead,
	TableHeadCell,
} from "../../components/Table";
import Text from "../../components/Text";
import penIcon from "../../img/pen.svg";
import {
	clearWords,
	deleteWordAsync,
	getDictionaryAsync,
} from "../../redux/dictionarySlice";
import { RootState } from "../../redux/store";

const TableIcon = styled(Svg).attrs({ src: penIcon, color: "white" })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #eb5757;
  border-radius: 8px;
  width: 49px;
  height: 38px;
  padding: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledBinIcon = styled(BinIcon).attrs({ color: "white" })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  width: 49px;
  padding: 8px;
  background-color: #eb5757;
  border-radius: 8px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledTableRow = styled.tr`
  border-bottom: 1px solid #eb5757;

  td {
    padding: 28px 0;

    &:first-child {
      padding-left: 22px;
    }
  }
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: table-row-group;
`;

const LIMIT = 20;

const DictionaryPage = ({ history }: RouteComponentProps) => {
	const dispatch = useDispatch();

	const isFirstRender = useRef(true);

	const { words, isSourceLangRus, count } = useSelector(
		({ dictionary }: RootState) => dictionary,
	);

	const [query, setQuery] = useQueryParams({
		search: withDefault(StringParam, undefined),
		page: withDefault(NumberParam, undefined),
	});

	useEffect(() => {
		dispatch(clearWords());
		// setQuery({ page: undefined });
	}, [dispatch, query.search]);

	useEffect(() => {
		if (isFirstRender.current) {
			dispatch(
				getDictionaryAsync({
					search: query.search,
					page: 1,
					limit: (query.page ? Number(query.page) : 1) * LIMIT,
				}),
			);

			isFirstRender.current = false;
		}
	}, [dispatch, query.search, query.page]);

	useEffect(() => {
		return () => {
			dispatch(clearWords());
		};
	}, [dispatch]);

	const handleLoadMore = useCallback(
		async (page: number) => {
			await dispatch(
				getDictionaryAsync({
					page,
					search: query.search,
					limit: LIMIT,
				}),
			);

			setQuery((latestValues) => ({
				page: latestValues.page ? Number(latestValues.page) + 1 : 2,
			}));
		},
		[dispatch, setQuery, query],
	);

	const hasMore = query.page ? Number(query.page) * LIMIT < count : true;

	return (
		<Page sidebar={<DictionarySidebar />}>
			<TableContainer>
				<Table>
					<TableHead>
						<tr>
							<TableHeadCell style={{ paddingLeft: 30 }} align='left'>
								слово
							</TableHeadCell>
							<TableHeadCell align='left'>перевод</TableHeadCell>
							<TableHeadCell align='left'>ред.</TableHeadCell>
							<TableHeadCell align='left'>удалить</TableHeadCell>
						</tr>
					</TableHead>
					<StyledInfiniteScroll
						pageStart={1}
						loadMore={handleLoadMore}
						hasMore={hasMore}
						loader={<div></div>}
						initialLoad={false}
					>
						{words.map((word) => (
							<StyledTableRow key={word._id}>
								<td>
									<Text color='#322C27' size={24}>
										{isSourceLangRus ? word.rus : word.ing}
									</Text>
								</td>
								<td>
									<Text color='#322C27' size={24}>
										{isSourceLangRus ? word.ing : word.rus}
									</Text>
								</td>
								<td>
									<TableIcon
										onClick={() => history.push(`/vocabulary/${word._id}`)}
									/>
								</td>
								<td>
									<StyledBinIcon
										onClick={() => {
											if (window.confirm("Удалить перевод?")) {
												dispatch(
													deleteWordAsync(word._id, () => {
														dispatch(getDictionaryAsync());
													}),
												);
											}
										}}
									/>
								</td>
							</StyledTableRow>
						))}
					</StyledInfiniteScroll>
				</Table>
			</TableContainer>
		</Page>
	);
};

export default DictionaryPage;
