import React, { useMemo, useState } from "react";
import styled from "styled-components";
import BinIcon from "../BinIcon";
import Flex from "../Flex";
import Text from "../Text";
import { UserAvatarProps } from "./interface";
import defaultAvatar from "./defaultAvatar.png";

const Container = styled.div`
  flex: none;
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-flex;
  width: 102px;
  height: 102px;
  margin-right: 24px;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const BinIconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-flex;
  width: 43px;
  height: 43px;
  background-color: #828282;
  border-radius: 50%;

  > div {
    margin: auto;
  }
`;

const UserAvatar = ({
	src,
	firstName,
	lastName,
	middleName,
	...restProps
}: UserAvatarProps) => {
	const [isError, setIsError] = useState(false);

	const img = useMemo(() => {
		return isError || !src ? defaultAvatar : src;
	}, [isError, src]);

	return (
		<Container {...restProps}>
			<ImageContainer>
				<img src={img} alt='user' onError={() => setIsError(true)} />
				<BinIconContainer>
					<BinIcon />
				</BinIconContainer>
			</ImageContainer>
			<Flex direction='column'>
				<Text weight={400} size={32} color='#828282'>
					{firstName}
				</Text>
				<Text weight={400} size={32} color='#828282'>
					{lastName}
				</Text>
				<Text weight={400} size={32} color='#828282'>
					{middleName}
				</Text>
			</Flex>
		</Container>
	);
};

export default styled(UserAvatar)({});
