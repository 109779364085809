import styled from "styled-components";
import SidebarBlock from "../SidebarBlock";

export const StyledAboutPageSidebar = styled.div`
  max-width: 267px;
  margin-right: 53px;
  padding: 20px 8px;
  background-color: #828282;

  ${SidebarBlock} {
    padding: 20px 8px;
  }
`;
