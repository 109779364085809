import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import StarIcon from "../StarIcon";
import { TaskLevelProps } from "./interface";

const TaskLevelContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const TaskLevel = ({ level, onChange }: TaskLevelProps) => {
	const createClickHandler = useCallback(
		(nextLevel: string) => () => {
			if (onChange) {
				onChange(nextLevel);
			}
		},
		[onChange],
	);

	const isReadOnly = useMemo(() => !onChange, [onChange]);

	return (
		<TaskLevelContainer>
			{["1", "2", "3"].map((levelValue) => {
				const isActive = parseInt(level, 10) >= parseInt(levelValue, 10);

				if (isReadOnly && !isActive) {
					return null;
				}

				return (
					<StarIcon
						onClick={createClickHandler(levelValue)}
						key={levelValue}
						color={!isReadOnly && isActive ? "#F2994A" : "white"}
					/>
				);
			})}
		</TaskLevelContainer>
	);
};

export default TaskLevel;
