import styled from "styled-components";
import getTheme from "../../utils/getTheme";

const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  background-color: ${getTheme("white")};
  border: none;
  outline: none;
  font: 400 18px ${getTheme("fontFamily")};
  color: ${getTheme("black")};
  resize: none;
`;

export default Textarea;
