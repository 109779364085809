import { TaskTypes } from "../../types/tasks";

import DividerRed from "../DividerRed";
import Flex from "../Flex";
import TaskAnswerVariants from "../TaskAnswerVariants";
import TaskTextEditor from "../TaskTextEditor";

import { TaskFreeAnswerFormProps } from "./interface";

const TaskFreeAnswerForm: React.FC<TaskFreeAnswerFormProps> = () => {
	return (
		<Flex direction='column'>
			<TaskTextEditor />
			<DividerRed />
			<TaskAnswerVariants
				type={TaskTypes.audioFreeAnswer}
				isFreeAnswer={true}
			/>
		</Flex>
	);
};

export default TaskFreeAnswerForm;
