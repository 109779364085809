import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setTaskParams } from "../redux/tasksSlice";

export const useTaskAnswerState = (answer: string) => {
	const dispatch = useDispatch();

	const bindCheckbox = useCallback(
		(index: number) => {
			return {
				isChecked: answer === String(index),
				onChange: () => {
					dispatch(setTaskParams({ answer: String(index) }));
				},
			};
		},
		[dispatch, answer],
	);

	return { bindCheckbox };
};

export default useTaskAnswerState;
