import React from "react";
import { NavLink } from "react-router-dom";
import SidebarBlock from "../SidebarBlock";
import Text from "../Text";
import { StyledAboutPageSidebar } from "./AboutPageSidebarStyled";

const AboutPageSidebar = () => {
	return (
		<StyledAboutPageSidebar>
			<SidebarBlock>
				<Text
					color='white'
					activeStyle={{ color: "#CCFF33" }}
					textTransform='uppercase'
					textDecoration='none'
					size={24}
					weight={700}
					as={NavLink}
					to='/about/app'
				>
					о приложении Samott
				</Text>
			</SidebarBlock>
			<SidebarBlock>
				<Text
					color='white'
					activeStyle={{ color: "#CCFF33" }}
					textTransform='uppercase'
					textDecoration='none'
					size={24}
					weight={700}
					as={NavLink}
					to='/about/author'
				>
					Автор проекта
				</Text>
			</SidebarBlock>
			<SidebarBlock>
				<Text
					color='white'
					activeStyle={{ color: "#CCFF33" }}
					textTransform='uppercase'
					textDecoration='none'
					size={24}
					weight={700}
					as={NavLink}
					to='/about/banner'
				>
					Баннер
				</Text>
			</SidebarBlock>
			<SidebarBlock>
				<Text
					color='white'
					activeStyle={{ color: "#CCFF33" }}
					textTransform='uppercase'
					textDecoration='none'
					size={24}
					weight={700}
					as={NavLink}
					to='/about/grammar'
				>
					Грамматика
				</Text>
			</SidebarBlock>
		</StyledAboutPageSidebar>
	);
};

export default AboutPageSidebar;
