import React from "react";
import styled from "styled-components";
import Flex from "../Flex";
import StarIcon from "../StarIcon";
import Text from "../Text";
import { UserTaskInfoLevelConfig, UserTaskInfoLevelProps } from "./interface";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  height: 48px;
  margin: 12px 0 0 -6px;

  & + & {
    margin-top: 22px;
  }
`;

const TaskLevel = styled.div<{
	backgroundColor: string;
}>`
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 0 14px 14px 0;
  height: 100%;
  padding: 0 16px;
`;

const Value = styled(Text)<{
	backgroundColor: string;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 112px;
  padding: 0 16px;
  margin-left: 35px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 14px;
`;

const backgroundColors: UserTaskInfoLevelConfig = {
	"0": "#DBD8A5",
	"1": "#FF9966",
	"2": "#FF6633",
};

const starsColors: UserTaskInfoLevelConfig = {
	"0": "#DF5656",
	"1": "#828282",
	"2": "#CCFF33",
};

const labels: UserTaskInfoLevelConfig = {
	"0": "легкий",
	"1": "средний",
	"2": "сложный",
};

const UserTasksInfoLevel = (props: UserTaskInfoLevelProps) => {
	const { level, value } = props;

	const backgroundColor = backgroundColors[level];

	const starsColor = starsColors[level];

	const label = labels[level];

	return (
		<Container>
			<TaskLevel backgroundColor={backgroundColor}>
				<Flex alignItems='center'>
					{Array(level + 1)
						.fill(null)
						.map((item, index) => (
							<StarIcon key={index} color={starsColor} />
						))}
				</Flex>
				<Text color='white' size={18} weight={700}>
					{label}
				</Text>
			</TaskLevel>
			<Value
				backgroundColor={backgroundColor}
				color='white'
				size={18}
				weight={700}
			>
				{value}
			</Value>
		</Container>
	);
};

export default UserTasksInfoLevel;
