import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import { setTaskParams } from "../../redux/tasksSlice";
import { TaskOne } from "../../types/tasks";
import useTaskAnswerState from "../../utils/useTaskAnswerState";
import Checkbox, { CheckboxContainer } from "../Checkbox";
import ImageUpload from "../ImageUpload";

const ImageUploadCarouselContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
`;

const ImageUploadWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;

  ${CheckboxContainer} {
    margin: 10px 10px 0 0;
  }
`;

const ImageUploadCarousel = () => {
	const dispatch = useDispatch();

	const { photos, answer } = useSelector((state: RootState) => {
		const params = state.tasks.task?.params as TaskOne["params"] | undefined;

		if (params) {
			return {
				photos: params.photos,
				answer: params.answer,
			};
		}

		return {
			photos: [],
			answer: "",
		};
	});

	const { bindCheckbox } = useTaskAnswerState(answer);

	const bindImageUpload = useCallback(
		(index: number) => ({
			file: photos[index],
			onChange: (path: string) => {
				const nextPhotos = photos.slice();

				if (path) {
					if (nextPhotos[index]) {
						nextPhotos[index] = path;
					} else {
						nextPhotos.push(path);
					}
				} else {
					if (nextPhotos[index]) {
						nextPhotos.splice(index, 1);
					}
				}

				dispatch(setTaskParams({ photos: nextPhotos }));
			},
		}),
		[dispatch, photos],
	);

	return (
		<ImageUploadCarouselContainer>
			{[0, 1, 2, 3].map((index) => (
				<ImageUploadWrapper key={index}>
					<ImageUpload {...bindImageUpload(index)} />
					<Checkbox {...bindCheckbox(index)} />
				</ImageUploadWrapper>
			))}
		</ImageUploadCarouselContainer>
	);
};

export default ImageUploadCarousel;
