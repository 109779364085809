import React, { useCallback } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import Svg from "../Svg";
import Text from "../Text";
import { NavBarLinkProps } from "./interface";

const Container = styled(NavLink)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;

  & + & {
    margin-left: 20px;
  }

  &.active {
    color: ${getTheme("salad")};
  }
`;

const NavBarLink = (props: NavBarLinkProps) => {
	const { icon, label, to } = props;

	const checkActive = useCallback<Required<NavLinkProps>["isActive"]>(
		(match, location) => {
			if (to === "/") {
				return match !== null;
			}
			return location.pathname.startsWith(to);
		},
		[to],
	);

	return (
		<Container to={to} exact isActive={checkActive}>
			<Svg size={32} src={icon} color='inherit' />
			<Text weight={300} size={12} color='inherit'>
				{label}
			</Text>
		</Container>
	);
};

export default NavBarLink;
