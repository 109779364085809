import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
  object-position: center;
`;

export default Image;
