import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	NumberParam,
	StringParam,
	useQueryParam,
	useQueryParams,
	withDefault,
} from "use-query-params";

import Pagination from "../../components/Pagination";
import ParamButton from "../../components/ParamButton";
import TasksPageContainer from "../../components/TasksPageContainer";
import TasksTable from "../../components/TasksTable";
import { RootState } from "../../redux/store";
import { requestTasks, setPage } from "../../redux/tasksSlice";
import { TaskTypes } from "../../types/tasks";

export const taskTypes: { type?: TaskTypes; label: string }[] = [
	{
		type: undefined,
		label: "все",
	},
	{
		type: TaskTypes.images,
		label: "задание с изображениями",
	},
	{
		type: TaskTypes.correctTranslate,
		label: "выбрать правильный перевод",
	},
	{
		type: TaskTypes.audio,
		label: "аудиозадание",
	},
	{
		type: TaskTypes.audioFreeAnswer,
		label: "аудиозадание со свободным ответом",
	},
	{
		type: TaskTypes.space,
		label: "заполнить пропуск",
	},
	{
		type: TaskTypes.video,
		label: "видеозадание",
	},
	{
		type: TaskTypes.pairs,
		label: "соотнесите пары слов",
	},
	{
		type: TaskTypes.freeAnswer,
		label: "задание со свободным ответом",
	},
	{
		type: TaskTypes.wordByLetters,
		label: "составь слово",
	},
];

const LIMIT = 20;

const TasksPage = () => {
	const dispatch = useDispatch();

	const count = useSelector(({ tasks }: RootState) => tasks.count);
	const initialPage = useSelector(({ tasks }: RootState) => tasks.page);

	const [query, setQuery] = useQueryParams({
		page: withDefault(NumberParam, initialPage),
		level: withDefault(StringParam, undefined),
	});

	const [type] = useQueryParam<TaskTypes>("type");
	const typeValue = type || undefined;

	const getTasks = useCallback(() => {
		dispatch(
			requestTasks(typeValue, {
				page: query.page,
				limit: LIMIT,
				level: query.level,
			}),
		);
	}, [dispatch, typeValue, query.page, query.level]);

	useEffect(() => {
		getTasks();
	}, [getTasks]);

	const page = query.page ? Number(query.page) : 1;
	const pageCount = Math.ceil(count / LIMIT);

	const handlePageChange = (nextPage: number) => {
		setQuery({ page: nextPage });
		dispatch(setPage(nextPage));
	};

	return (
		<TasksPageContainer>
			<div>
				{[undefined, "1", "2", "3", "test"].map((level) => (
					<ParamButton
						key={level}
						isActive={query.level === level}
						onClick={() => setQuery({ level, page: undefined })}
					>
						{level === "test"
							? "Тесты"
							: level === undefined
							? "Все"
							: `Уровень ${level}`}
					</ParamButton>
				))}
			</div>
			<TasksTable updateTasks={getTasks} />
			<Pagination
				onChange={handlePageChange}
				page={page}
				pageCount={pageCount}
			/>
		</TasksPageContainer>
	);
};
export default TasksPage;
