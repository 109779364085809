import styled from "styled-components";
import Flex from "../Flex";
import Svg from "../Svg";

export const StyledLanguageSwitcher = styled(Flex).attrs({
	alignItems: "center",
})`
  cursor: pointer;
  user-select: none;

  ${Svg} {
    margin: 0 10px;
  }
`;
