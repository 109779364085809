import Page from "../Page";
import ChaptersPageSidebar from "../ChaptersPageSidebar";

import { ChaptersPageContainerProps } from "./interface";

const ChaptersPageContainer = ({
	children,
	className,
}: ChaptersPageContainerProps) => {
	return (
		<Page className={className} sidebar={<ChaptersPageSidebar />}>
			{children}
		</Page>
	);
};

export default ChaptersPageContainer;
