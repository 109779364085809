import React, {
	ReactText,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import useOnClickOutside from "../../utils/useOnClickOutside";
import SelectChevron from "../SelectChevron";
import SelectContext from "../SelectContext";
import Text from "../Text";
import { SelectProps } from "./interface";

const SelectContainer = styled.div<{ minWidth?: number }>`
  position: relative;
  display: inline-flex;
  min-width: ${(props) => props.minWidth && `${props.minWidth}px`};
`;

const SelectLabel = styled.div`
  display: inline-flex;
  width: 100%;
  background-color: ${getTheme("white")};
  border: 1px solid ${getTheme("black")};
  cursor: pointer;

  ${Text} {
    width: 100%;
    padding: 4px;
  }
`;

const SelectOptionsList = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  display: ${(props) => (props.isOpen ? "block" : "none")};
  width: 100%;
  padding: 9px 0;
  background-color: ${getTheme("white")};
  border: 1px solid ${getTheme("black")};
  z-index: ${getTheme("selectZIndex")};
`;

const Select = (props: SelectProps) => {
	const { label = "", value, onChange, children, className, minWidth } = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const [labelValue, setLabelValue] = useState<ReactText>(label);

	const [isOpen, setIsOpen] = useState(false);

	const toggleIsOpen = useCallback(
		() => setIsOpen((prevState) => !prevState),
		[],
	);

	const closeSelect = useCallback(() => setIsOpen(false), []);

	useOnClickOutside(containerRef, closeSelect);

	useEffect(() => {
		if (!value) {
			setLabelValue(label);
		}
	}, [label, value]);

	const setLabel = useCallback(
		(nextLabelValue: ReactText) => {
			if (nextLabelValue) {
				setLabelValue(nextLabelValue);
			} else {
				setLabelValue(label);
			}
		},
		[label],
	);

	return (
		<SelectContext.Provider value={{ setLabel, value, onChange, setIsOpen }}>
			<SelectContainer
				ref={containerRef}
				className={className}
				minWidth={minWidth}
			>
				<SelectLabel onClick={toggleIsOpen}>
					<Text weight={300} size={18}>
						{labelValue}
					</Text>
					<SelectChevron isActive={isOpen} />
				</SelectLabel>
				<SelectOptionsList isOpen={isOpen}>{children}</SelectOptionsList>
			</SelectContainer>
		</SelectContext.Provider>
	);
};

export default Select;
