import { useSelector } from "react-redux";

import AuthPage from "./pages/AuthPage";
import AppContainer from "./components/AppContainer";
import GlobalStyles from "./components/GlobalStyles";
import Header from "./components/Header";
import Router from "./components/Router";
import Wrapper from "./components/Wrapper";
import { RootState } from "./redux/store";

const App: React.FC = () => {
	const isAuth = useSelector((state: RootState) => state.user.isAuth);

	return (
		<div>
			<GlobalStyles />
			{isAuth ? (
				<AppContainer>
					<Wrapper>
						<Header />
					</Wrapper>
					<Router />
				</AppContainer>
			) : (
				<AuthPage />
			)}
		</div>
	);
};

export default App;
