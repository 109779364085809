import styled from "styled-components";
import Button from "../Button";

const ButtonBlue = styled(Button)`
  height: 41px;
  background-color: #2f80ed;
  padding: 0 40px;
`;

export default ButtonBlue;
