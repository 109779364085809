import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { rgba } from "polished";

import FormInput from "../../components/FormInput";
import { clearTask } from "../../redux/tasksSlice";
import {
	createChapterAsync,
	updateChapterAsync,
	requestChapter,
} from "../../redux/chaptersSlice";
import ButtonOrange from "../../components/ButtonOrange";
import DividerRed from "../../components/DividerRed";
import FormGroup from "../../components/FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../../components/FormGroup/interface";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import TaskLevel from "../../components/TaskLevel";
import ChaptersPageContainer from "../../components/ChaptersPageContainer";

import { ChapterPageProps } from "./interface";
import Textarea from "../../components/Textarea";

const ChapterPage = ({ match, history }: ChapterPageProps) => {
	const { id } = match.params;
	const theme = useTheme();
	const dispatch = useDispatch();
	const [title, setTitle] = useState("");
	const [level, setLevel] = useState("");
	const [number, setNumber] = useState(0);

	useEffect(() => {
		if (id !== "new") {
			(async () => {
				const response: any = await dispatch(requestChapter(id));
				if (response) {
					setTitle(response.title);
					setLevel(response.level);
					setNumber(response.number);
				}
			})();
		}
	}, [dispatch, id]);

	useEffect(() => {
		return () => {
			dispatch(clearTask());
		};
	}, [dispatch]);

	const handleChangeLevel = useCallback((nextLevel: string) => {
		setLevel(nextLevel);
	}, []);

	const handleChangeNumber = useCallback((e: any) => {
		setNumber(Number(e.target.value.replace(/\D/g, "")));
	}, []);

	const saveChapter = useCallback(() => {
		const success = () => {
			history.push({
				pathname: "/chapters",
			});
		};

		if (id !== "new") {
			dispatch(updateChapterAsync(id, { title, level, number }, success));
		} else {
			dispatch(createChapterAsync({ title, level, number }, success));
		}
	}, [id, history, dispatch, title, level, number]);

	return (
		<ChaptersPageContainer>
			<GoBackButton
				to={"/chapters"}
				backgroundColor={rgba(theme.lightGrayBlue, 0.7)}
			/>

			<FormGroup
				label='Введите название (не более 30 знаков)'
				labelPlacement={FormGroupLabelPlacements.top}
				margin={FormGroupMargins.normal}
			>
				<Textarea
					value={title}
					onChange={(event) => setTitle(event.target.value)}
				/>
			</FormGroup>

			<DividerRed />

			<FormGroup label='уровень:' margin={FormGroupMargins.normal}>
				<TaskLevel level={level} onChange={handleChangeLevel} />
			</FormGroup>

			<DividerRed />

			<FormGroup label='номер:' margin={FormGroupMargins.normal}>
				<FormInput value={number} onChange={handleChangeNumber} />
			</FormGroup>

			<ButtonOrange onClick={saveChapter}>сохранить</ButtonOrange>
		</ChaptersPageContainer>
	);
};

export default ChapterPage;
