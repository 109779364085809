import { createSlice } from "@reduxjs/toolkit";
import { gql } from "graphql-request";
import client from "../utils/request";
import { AppThunk } from "./store";

export interface Word {
	_id: string;
	rus: string;
	ing: string;
}

interface DictionaryState {
	words: Word[];
	count: number;
	isSourceLangRus: boolean;
}

const initialState: DictionaryState = {
	words: [],
	count: 0,
	isSourceLangRus: false,
};

const dictionarySlice = createSlice({
	name: "dictionary",
	initialState,
	reducers: {
		setWords: (state, action) => {
			state.words = [...state.words, ...action.payload.data];
			state.count = action.payload.meta.count;
		},
		clearWords: (state) => {
			state.words = [];
			state.count = 0;
		},
		setSourceLang: (state) => {
			state.isSourceLangRus = !state.isSourceLangRus;
		},
	},
});

export const { setWords, setSourceLang, clearWords } = dictionarySlice.actions;

export const getDictionaryAsync =
	(params?: {
		search?: string;
		page?: number;
		limit?: number;
	}): AppThunk =>
	async (dispatch, getState) => {
		try {
			const { isSourceLangRus } = getState().dictionary;

			const response = await client.request(
				gql`
        query GetWords($ing: String, $rus: String, $page: Int, $limit: Int) {
          word(
            query: { ing: $ing, rus: $rus }
            pagination: { page: $page, limit: $limit }
          ) {
            data {
              _id
              ing
              rus
            }
            meta {
              count
            }
          }
        }
      `,
				{
					ing: isSourceLangRus ? undefined : params?.search,
					rus: isSourceLangRus ? params?.search : undefined,
					limit: params?.limit,
					page: params?.page,
				},
			);

			dispatch(setWords(response.word));
		} catch (e) {}
	};

export const getWordAsync =
	(id: string): AppThunk =>
	async (dispatch) => {
		try {
			const response = await client.request(
				gql`
        query GetDictionary($id: String) {
          word(query: { _id: $id }) {
            data {
              _id
              ing
              rus
            }
            meta {
              count
            }
          }
        }
      `,
				{ id },
			);

			return response.word;
		} catch (e) {}
	};

export const createWordAsync =
	(rus: string, ing: string, success: () => void): AppThunk =>
	async (dispatch) => {
		try {
			const response = await client.request(
				gql`
        mutation CreateWord($rus: String!, $ing: String!) {
          createWord(wordData: { rus: $rus, ing: $ing })
        }
      `,
				{ rus, ing },
			);

			if (response.createWord) {
				success();
			}
		} catch (e) {}
	};

export const updateWordAsync =
	(id: string, rus: string, ing: string, success: () => void): AppThunk =>
	async (dispatch) => {
		try {
			const response = await client.request(
				gql`
        mutation UpdateWord($rus: String!, $ing: String!, $id: String!) {
          updateWord(wordData: { ing: $ing, rus: $rus, _id: $id })
        }
      `,
				{ rus, ing, id },
			);

			if (response.updateWord) {
				success();
			}
		} catch (e) {}
	};

export const deleteWordAsync =
	(id: string, success: () => void): AppThunk =>
	async (dispatch) => {
		try {
			const response = await client.request(
				gql`
        mutation CreateWord($id: String!) {
          deleteWord(_id: $id)
        }
      `,
				{ id },
			);

			if (response.deleteWord) {
				success();
			}
		} catch (e) {}
	};

export default dictionarySlice.reducer;
