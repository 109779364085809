import styled from "styled-components";
import Svg from "../Svg";
import { HeaderBackgroundProps } from "./interface";
import bg from "./bg.svg";

const HeaderBackground = styled(Svg).attrs({ src: bg })<HeaderBackgroundProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: ${(props) => props.color};
`;

export default HeaderBackground;
