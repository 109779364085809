import styled from "styled-components";
import Button from "../Button";
import Svg from "../Svg";

export const StyledEditButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #828282;
  border-radius: 7px;
  text-decoration: none;

  ${Svg} {
    margin-left: 44px;
  }
`;
