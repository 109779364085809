import styled from "styled-components";
import Text from "../Text";
import bg from "./bg.svg";

const Title = styled(Text).attrs({
	as: "h1",
	textTransform: "uppercase",
	color: "#fff",
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 658px;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export default Title;
