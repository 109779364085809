import React from "react";
import styled from "styled-components";
import DividerRed from "../DividerRed";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import ImageUploadCarousel from "../ImageUploadCarousel";
import TaskTextEditor from "../TaskTextEditor";

const TaskImageFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskImageForm = () => {
	return (
		<TaskImageFormContainer>
			<TaskTextEditor />
			<DividerRed />
			<FormGroup
				margin={FormGroupMargins.normal}
				label='Прикрепить изображение'
				labelPlacement={FormGroupLabelPlacements.top}
			>
				<ImageUploadCarousel />
			</FormGroup>
			<DividerRed />
		</TaskImageFormContainer>
	);
};

export default TaskImageForm;
