import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import ChaptersPageContainer from "../../components/ChaptersPageContainer";
import ChaptersTable from "../../components/ChaptersTable";
import { getChaptersAsync } from "../../redux/chaptersSlice";

const ChapterPage = () => {
	const dispatch = useDispatch();

	const getChapters = useCallback(() => {
		dispatch(getChaptersAsync());
	}, [dispatch]);

	useEffect(() => {
		getChapters();
	}, [getChapters]);

	return (
		<ChaptersPageContainer>
			<ChaptersTable updateChapters={getChapters} />
		</ChaptersPageContainer>
	);
};

export default ChapterPage;
