import { useCallback, useState } from "react";

const useFileUpload = () => {
	const [loading, setLoading] = useState(false);

	const uploadFile = useCallback(async (file: File) => {
		try {
			setLoading(true);

			const formData = new FormData();

			formData.append("file", file);

			const response = await fetch("/api/admin/upload", {
				method: "POST",
				body: formData,
			});

			if (!response.ok) {
				throw new Error();
			}

			alert("Файл успешно загружен");

			return (await response.json()).data.path;
		} catch (error) {
			alert("Ошибка при загрузке файла");
		} finally {
			setLoading(false);
		}
	}, []);

	return { uploadFile, loading };
};

export default useFileUpload;
