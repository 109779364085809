import React, { ChangeEvent, useCallback } from "react";
import Spinner from "react-spinner-material";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import useFileUpload from "../../utils/useFileUpload";
import BinIcon from "../BinIcon";
import Image from "../Image";
import { ImageUploadProps } from "./interface";
import uploadImage from "./upload.png";
import docImage from "./doc.png";

const ImageUploadContainer = styled.div`
  position: relative;
  width: 140px;
  height: 104px;
  background-color: ${getTheme("darkGray2")};
  border-radius: 15px;

  ${BinIcon} {
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${getTheme("white")};
    z-index: 2;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
`;

const StyledSpinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -16px;
  margin-left: -16px;
`;

const ImageUpload = (props: ImageUploadProps) => {
	const { file, onChange, type } = props;

	const { uploadFile, loading } = useFileUpload();

	const handleClick = useCallback(() => {
		if (file) {
			window.open(file, "_blank");
		}
	}, [file]);

	const handleChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) {
				return;
			}

			const file = event.target.files[0];

			const path = await uploadFile(file);

			if (path) {
				onChange(path);
			}
		},
		[onChange, uploadFile],
	);

	const deleteFile = useCallback(() => {
		onChange("");
	}, [onChange]);

	const src =
		type === "doc" ? (file ? docImage : uploadImage) : file || uploadImage;

	return (
		<ImageUploadContainer>
			<input
				onClick={handleClick}
				type='file'
				onChange={handleChange}
				value=''
			/>
			<Image src={src} />
			{file && <BinIcon onClick={deleteFile} />}
			{loading && (
				<StyledSpinner>
					<Spinner radius={32} stroke={4} visible color='#F2994A' />
				</StyledSpinner>
			)}
		</ImageUploadContainer>
	);
};

export default ImageUpload;
