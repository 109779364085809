import React from "react";
import styled from "styled-components";
import Background from "../Background";
import Wrapper from "../Wrapper";
import { PageProps } from "./inderface";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  padding-top: 5px;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
`;

const Page = (props: PageProps) => {
	const { children, sidebar, isMirroredBackground, className } = props;

	return (
		<Wrapper isFullHeight className={className}>
			<Background isMirrored={isMirroredBackground} />
			<Container>
				{sidebar}
				<Content>{children}</Content>
			</Container>
		</Wrapper>
	);
};

export default Page;
