import React from "react";
import { TaskTypes } from "../../types/tasks";
import Svg from "../Svg";
import imageIcon from "./image.svg";
import { TaskVariantIconProps } from "./interface";
import translateIcon from "./translate.svg";
import audioIcon from "./audio.svg";
import audioFreeAnswerIcon from "./audioFreeAnswer.svg";
import spaceIcon from "./space.svg";

const icons: { [key in TaskTypes]: string } = {
	[TaskTypes.images]: imageIcon,
	[TaskTypes.correctTranslate]: translateIcon,
	[TaskTypes.audio]: audioIcon,
	[TaskTypes.audioFreeAnswer]: audioFreeAnswerIcon,
	[TaskTypes.space]: spaceIcon,
	[TaskTypes.video]: imageIcon,
	[TaskTypes.pairs]: imageIcon,
	[TaskTypes.freeAnswer]: imageIcon,
	[TaskTypes.wordByLetters]: imageIcon,
};

const size = [58, 38];

const TaskVariantIcon = ({ type }: TaskVariantIconProps) => {
	return <Svg size={size} isInitialColor src={icons[type]} />;
};

export default TaskVariantIcon;
