import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import Input from "../Input";

const InputSecond = styled(Input)`
  padding: 6px 16px;
  background-color: white;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-radius: 7px;
  font-size: 32px;
  font-weight: 400;
  color: ${getTheme("copperPink")};
  text-align: center;
  line-height: normal;
`;

export default InputSecond;
