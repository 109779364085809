import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import penIcon from "../../img/pen.svg";
import Flex from "../Flex";
import Svg from "../Svg";
import { Table, TableHead, TableContainer } from "../Table";
import Text from "../Text";
import { UsersTableProps } from "./interface";

const TableBody = styled.tbody`
  td {
    padding: 12px 0;
    text-align: center;

    > div {
      display: flex;
      height: 38px;
      padding: 8px 0;
      background-color: #219653;

      > div {
        width: 100%;
      }
    }

    & + td {
      > div {
        > div {
          border-left: 1px solid white;
        }
      }
    }

    &:last-of-type {
      > div {
        border-radius: 0 6px 6px 0;
      }

      ${Svg} {
        cursor: pointer;
      }
    }
  }
`;

const UsersTable = ({ users }: UsersTableProps) => {
	const history = useHistory();

	const createClickHandler = useCallback(
		(userId: string) => () => {
			history.push(`/users/${userId}`);
		},
		[history],
	);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<tr>
						<th>
							<div />
						</th>
						<th>
							<Flex alignItems='center' justify='center'>
								<Text size={14} weight={300} color='white'>
									Имя пользователя
								</Text>
							</Flex>
						</th>
						<th>
							<Flex alignItems='center' justify='center'>
								<Text size={14} weight={300} color='white'>
									тел. номер
								</Text>
							</Flex>
						</th>
						<th>
							<Flex alignItems='center' justify='center'>
								<Text size={14} weight={300} color='white'>
									электронная почта
								</Text>
							</Flex>
						</th>
						<th>
							<Flex alignItems='center' justify='center'>
								<Text size={14} weight={300} color='white'>
									дата регистрации
								</Text>
							</Flex>
						</th>
						<th>
							<div />
						</th>
					</tr>
				</TableHead>
				<TableBody>
					{users.map((user) => (
						<tr key={user._id}>
							<td>
								<div>
									<div />
								</div>
							</td>
							<td>
								<div>
									<Flex alignItems='center' justify='center'>
										<Text size={13} weight={300} color='white'>
											{user.firstName} {user.lastName}
										</Text>
									</Flex>
								</div>
							</td>
							<td>
								<div>
									<Flex alignItems='center' justify='center'>
										<Text size={13} weight={300} color='white'>
											{user.phone}
										</Text>
									</Flex>
								</div>
							</td>
							<td>
								<div>
									<Flex alignItems='center' justify='center'>
										<Text size={13} weight={300} color='white'>
											{user.email}
										</Text>
									</Flex>
								</div>
							</td>
							<td>
								<div>
									<Flex alignItems='center' justify='center'>
										<Text size={13} weight={300} color='white'>
											{new Date(user.createdAt).toLocaleDateString("ru")}
										</Text>
									</Flex>
								</div>
							</td>
							<td>
								<div>
									<Flex alignItems='center' justify='center'>
										<Svg
											onClick={createClickHandler(user._id)}
											src={penIcon}
											size={[17, 23]}
											color='white'
										/>
									</Flex>
								</div>
							</td>
						</tr>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default UsersTable;
