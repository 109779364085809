import request, { gql } from "graphql-request";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import { clearUser, setUser } from "../../redux/usersSlice";
import Flex from "../../components/Flex";
import FormElement from "../../components/FormElement";
import GoBackButton from "../../components/GoBackButton";
import InputContained from "../../components/InputContained";
import Page from "../../components/Page";
import Paper from "../../components/Paper";
import RefreshButton from "../../components/RefreshButton";
import StarIcon from "../../components/StarIcon";
import Text from "../../components/Text";
import UserAvatar from "../../components/UserAvatar";
import UserScores from "../../components/UserScores";
import UsersPageSidebar from "../../components/UsersPageSidebar";
import UserTasksInfo from "../../components/UserTasksInfo";
import { UserPageProps } from "./interface";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
`;

const LeftColumn = styled.div`
  flex-grow: 0.3;
  display: flex;
  flex-direction: column;

  ${GoBackButton} {
    margin-bottom: 8px;
  }

  ${UserAvatar} {
    margin-bottom: 22px;
  }

  ${UserScores} {
    margin-right: 54px;
  }

  > div {
    margin-top: 16px;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 64px 0 0 42px;

  > div {
    & + div {
      margin-top: 26px;
    }
  }
`;

const UserLevel = styled.div`
  display: inline-flex;
  padding: 6px 30px;
  border-radius: 14px;
  background-color: #dbd8a5;
`;

const UserPage = ({ match }: UserPageProps) => {
	const dispatch = useDispatch();

	const usersCount = useSelector((state: RootState) => state.users.count);
	const user = useSelector((state: RootState) => state.users.user);

	const requestUser = useCallback(() => {
		request(
			"/api/admin",
			gql`
          query {
              users(_id: "${match.params.id}", pagination: {}) {
                  _id
                  phone
                  email
                  createdAt
                  firstName
                  lastName
                  middleName
                  score
                  birthday
              }
              usersCount
          }
      `,
		).then((response) => {
			dispatch(setUser(response));
		});
	}, [match.params.id, dispatch]);

	useEffect(() => {
		requestUser();

		return () => {
			dispatch(clearUser());
		};
	}, [requestUser, dispatch]);

	return (
		<Page
			sidebar={<UsersPageSidebar usersCount={usersCount} />}
			isMirroredBackground
		>
			{user && (
				<Container>
					<LeftColumn>
						<GoBackButton
							backgroundColor='rgba(33, 150, 83, 0.5)'
							to='/users'
						/>
						<UserAvatar
							firstName={user.firstName}
							lastName={user.lastName}
							middleName={user.middleName}
						/>
						<Flex justify='space-between'>
							<UserScores scores={user.score} />
							<Paper backgroundColor='white'>
								<RefreshButton onClick={requestUser} />
							</Paper>
						</Flex>
						<Paper backgroundColor='white'>
							<Flex alignItems='center' justify='space-between'>
								<Text color='#666666' size={24} weight={300}>
									текущий уровень
								</Text>
								<UserLevel>
									<StarIcon color='#DF5656' />
								</UserLevel>
							</Flex>
						</Paper>
						<UserTasksInfo />
					</LeftColumn>
					<RightColumn>
						<FormElement label='Дата рождения'>
							<InputContained
								value={user.birthday?.slice(0, 10)}
								type='date'
								readOnly
							/>
						</FormElement>
						<FormElement label='Телефон'>
							<InputContained value={user.phone} readOnly />
						</FormElement>
						<FormElement label='Email'>
							<InputContained value={user.email} readOnly />
						</FormElement>
						<FormElement label='Дата регистрации'>
							<InputContained
								value={user.createdAt.slice(0, 10)}
								readOnly
								type='date'
							/>
						</FormElement>
					</RightColumn>
				</Container>
			)}
		</Page>
	);
};

export default UserPage;
