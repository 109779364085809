import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AboutPageForm from "../../components/AboutPageForm";
import AboutPageSidebar from "../../components/AboutPageSidebar";
import Page from "../../components/Page";
import { getAboutAsync } from "../../redux/aboutSlice";

const AboutAppPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAboutAsync());
	}, [dispatch]);

	return (
		<Page sidebar={<AboutPageSidebar />}>
			<AboutPageForm type='project' />
		</Page>
	);
};

export default AboutAppPage;
