import React from "react";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import { FormElementProps } from "./interface";
import Text from "../Text";

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const Label = styled(Text).attrs({ size: 24, weight: 300 })`
  display: inline-flex;
  margin-bottom: 12px;
  color: ${getTheme("dullGray")};
`;

const FormElement = (props: FormElementProps) => {
	const { children, label } = props;

	return (
		<Container>
			{label && <Label>{label}</Label>}
			{children}
		</Container>
	);
};

export default FormElement;
