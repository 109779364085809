import styled from "styled-components";
import ButtonBlue from "../ButtonBlue";

export const StyledAboutPageForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ButtonBlue} {
    align-self: flex-start;
  }
`;
