import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSourceLang } from "../../redux/dictionarySlice";
import { RootState } from "../../redux/store";
import ButtonAdd from "../ButtonAdd";
import LanguageSwitcher from "../LanguageSwitcher";
import SidebarBlock from "../SidebarBlock";
import { DictionarySidebarProps } from "./DictionarySidebarTypes";
import { StyledDictionarySidebar } from "./DictionarySidebarStyled";

const DictionarySidebar = (props: DictionarySidebarProps) => {
	const dispatch = useDispatch();

	const isSourceLangRus = useSelector(
		({ dictionary }: RootState) => dictionary.isSourceLangRus,
	);

	const handleChangeLanguage = useCallback(() => {
		dispatch(setSourceLang());
	}, [dispatch]);

	return (
		<StyledDictionarySidebar>
			<SidebarBlock>
				<LanguageSwitcher
					isSourceRus={isSourceLangRus}
					onChange={handleChangeLanguage}
				/>
			</SidebarBlock>
			<SidebarBlock>
				<ButtonAdd isLink to='/vocabulary/new'>
					Добавить слово
				</ButtonAdd>
			</SidebarBlock>
		</StyledDictionarySidebar>
	);
};

export default DictionarySidebar;
