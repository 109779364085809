import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	updateAboutAppAsync,
	updateAboutAuthorAsync,
} from "../../redux/aboutSlice";
import { RootState } from "../../redux/store";
import AboutPageFormImages from "../AboutPageFormImages";
import ButtonBlue from "../ButtonBlue";
import DividerRed from "../DividerRed";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import FormInput from "../FormInput";
import GoBackButton from "../GoBackButton/GoBackButton";
import { StyledAboutPageForm } from "./AboutPageFormStyled";
import { AboutPageFormProps } from "./interface";

const AboutPageForm = ({ type }: AboutPageFormProps) => {
	const dispatch = useDispatch();

	const store = useSelector((state: RootState) =>
		type === "project" ? state.about.project : state.about.author,
	);

	const [photos, setPhotos] = useState<string[]>([]);
	const [description, setDescription] = useState("");

	useEffect(() => {
		setPhotos(store.photos);
		setDescription(store.description);
	}, [store]);

	const save = useCallback(() => {
		const aboutPhotos = photos.filter(Boolean);

		try {
			if (type === "project") {
				dispatch(updateAboutAppAsync(aboutPhotos, description));
			} else {
				dispatch(updateAboutAuthorAsync(aboutPhotos, description));
			}

			alert("Изменения успешно сохранены");
		} catch (e) {
			alert("Ошибка при сохранении изменений");
		}
	}, [dispatch, type, photos, description]);

	return (
		<StyledAboutPageForm>
			<GoBackButton
				to='/'
				label='вернуться на главную'
				backgroundColor='#86C6A7'
			/>
			<FormGroup
				labelPlacement={FormGroupLabelPlacements.top}
				label='Введите текст (не более 1000 знаков)'
				margin={FormGroupMargins.normal}
			>
				<FormInput
					as='textarea'
					onChange={(event: any) => setDescription(event.target.value)}
					value={description}
				/>
			</FormGroup>
			<DividerRed />
			<FormGroup
				labelPlacement={FormGroupLabelPlacements.top}
				label='Прикрепить изображение'
				margin={FormGroupMargins.normal}
			>
				<AboutPageFormImages photos={photos} onChange={setPhotos} />
			</FormGroup>
			<DividerRed />
			<ButtonBlue onClick={save}>Сохранить</ButtonBlue>
		</StyledAboutPageForm>
	);
};

export default AboutPageForm;
