import { createSlice } from "@reduxjs/toolkit";
import getLocalStorage from "../utils/getLocalStorage";
import setLocalStorage from "../utils/setLocalStorage";
import { AppThunk } from "./store";

interface UserState {
	isAuth: boolean;
}

const STORAGE_IS_AUTH = "is_auth";

const initialState: UserState = {
	isAuth: Boolean(getLocalStorage(STORAGE_IS_AUTH)),
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setIsAuth: (state, action) => {
			state.isAuth = action.payload;
			setLocalStorage(STORAGE_IS_AUTH, action.payload);
		},
	},
});

export const { setIsAuth } = userSlice.actions;

export const login =
	(login: string, password: string): AppThunk =>
	(dispatch) => {
		fetch("/api/admin/login", {
			method: "POST",
			body: JSON.stringify({ login, password }),
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => {
			if (response.status === 200) {
				dispatch(setIsAuth(true));
			} else {
				alert("Ошибка авторизации");
			}
		});
	};

export default userSlice.reducer;
