import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setTaskParams } from "../../redux/tasksSlice";
import { TaskThree, TaskTypes } from "../../types/tasks";
import AudioUpload from "../AudioUpload";
import DividerRed from "../DividerRed";
import Flex from "../Flex";
import FormGroup from "../FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../FormGroup/interface";
import FormInput from "../FormInput";
import TaskAnswerVariants from "../TaskAnswerVariants";
import TaskTextEditor from "../TaskTextEditor";
import { TaskAudioFormProps } from "./interface";

const TaskAudioForm = ({ isFreeAnswer }: TaskAudioFormProps) => {
	const dispatch = useDispatch();

	const audio = useSelector(
		(state: RootState) =>
			(state.tasks.task!.params as TaskThree["params"]).sound,
	);

	const answer = useSelector(
		(state: RootState) =>
			(state.tasks.task!.params as TaskThree["params"]).answerArray,
	);

	const handleChangeAudio = useCallback(
		(nextAudio: string) => {
			dispatch(setTaskParams({ sound: nextAudio }));
		},
		[dispatch],
	);

	return (
		<Flex direction='column'>
			<TaskTextEditor />
			<DividerRed />
			<FormGroup
				margin={FormGroupMargins.normal}
				label='прикрепить запись (файл не более 1 Мбайт)'
				labelPlacement={FormGroupLabelPlacements.top}
			>
				<Flex justify='center'>
					<AudioUpload src={audio} onChange={handleChangeAudio} />
				</Flex>
			</FormGroup>
			<DividerRed />
			<TaskAnswerVariants
				type={isFreeAnswer ? TaskTypes.audioFreeAnswer : TaskTypes.audio}
				isFreeAnswer={isFreeAnswer}
			/>
			{!isFreeAnswer && (
				<FormGroup
					margin={FormGroupMargins.normal}
					label='Ответ'
					labelPlacement={FormGroupLabelPlacements.top}
				>
					<FormInput value={(answer as string[]).join(" ")} readOnly />
				</FormGroup>
			)}
		</Flex>
	);
};

export default TaskAudioForm;
