import React, { useCallback } from "react";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import { CheckboxProps } from "./interface";

export const CheckboxContainer = styled.div`
  flex: none;
  display: inline-flex;
  width: 21px;
  height: 21px;
  border: 3px solid ${getTheme("grayKraiola")};
  background-color: transparent;
  cursor: pointer;
`;

const Indicator = styled.div<{ isChecked?: boolean }>`
  width: 11px;
  height: 11px;
  margin: auto;
  background-color: ${(props) =>
		props.isChecked ? props.theme.grayKraiola : "transparent"};
`;

const Checkbox = (props: CheckboxProps) => {
	const { isChecked, onChange } = props;

	const handleClick = useCallback(() => {
		if (onChange) {
			onChange();
		}
	}, [onChange]);

	return (
		<CheckboxContainer onClick={handleClick}>
			<Indicator isChecked={isChecked} />
		</CheckboxContainer>
	);
};

export default Checkbox;
