import React, { useCallback } from "react";
import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import Label from "../Label";
import { SwitchProps } from "./interface";

export const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;

  ${Label} {
    &:first-of-type {
      margin-right: 13px;
    }

    &:last-of-type {
      margin-left: 13px;
    }
  }
`;

const SwitchThumbnailContainer = styled.div`
  position: relative;
  display: inline-flex;
  width: 62px;
  height: 23px;
  background-color: ${getTheme("darkGray2")};
  border-radius: 16px;
`;

const SwitchThumbnail = styled.div<{ isChecked?: boolean }>`
  position: absolute;
  width: 28px;
  height: 19px;
  background-color: ${getTheme("white")};
  border-radius: 16px;
  top: 2px;
  left: ${(props) => (props.isChecked ? 32 : 2)}px;
`;

const Switch = (props: SwitchProps) => {
	const { isChecked, endLabel, startLabel, onChange } = props;

	const handleClick = useCallback(() => {
		if (onChange) {
			onChange(!isChecked);
		}
	}, [onChange, isChecked]);

	return (
		<SwitchContainer onClick={handleClick}>
			{startLabel && <Label>{startLabel}</Label>}
			<SwitchThumbnailContainer>
				<SwitchThumbnail isChecked={isChecked} />
			</SwitchThumbnailContainer>
			{endLabel && <Label>{endLabel}</Label>}
		</SwitchContainer>
	);
};

export default Switch;
