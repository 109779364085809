import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import ButtonBlue from "../../components/ButtonBlue";
import DictionarySidebar from "../../components/DictionarySidebar";
import DividerRed from "../../components/DividerRed";
import FormGroup from "../../components/FormGroup";
import {
	FormGroupLabelPlacements,
	FormGroupMargins,
} from "../../components/FormGroup/interface";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import Page from "../../components/Page";
import Textarea from "../../components/Textarea";
import {
	createWordAsync,
	getWordAsync,
	updateWordAsync,
} from "../../redux/dictionarySlice";

const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  margin-top: 14px;
`;

const WordPage = ({ match, history }: RouteComponentProps<{ id: string }>) => {
	const { id } = match.params;

	const dispatch = useDispatch();

	const [isSourceRus, setIsSourceRus] = useState(false);
	const [firstWord, setFirstWord] = useState("");
	const [secondWord, setSecondWord] = useState("");

	useEffect(() => {
		if (id !== "new") {
			(async () => {
				const response: any = await dispatch(getWordAsync(id));
				console.log(response);
				if (response?.data[0]) {
					setFirstWord(response.data[0].ing);
					setSecondWord(response.data[0].rus);
				}
			})();
		}
	}, [id, dispatch]);

	const save = () => {
		if (id === "new") {
			dispatch(
				createWordAsync(
					isSourceRus ? firstWord : secondWord,
					isSourceRus ? secondWord : firstWord,
					() => {
						alert("Перевод успешно сохранен");
						history.push("/vocabulary");
					},
				),
			);
		} else {
			dispatch(
				updateWordAsync(
					id,
					isSourceRus ? firstWord : secondWord,
					isSourceRus ? secondWord : firstWord,
					() => {
						alert("Перевод успешно сохранен");
						history.push("/vocabulary");
					},
				),
			);
		}
	};

	return (
		<Page sidebar={<DictionarySidebar />}>
			<GoBackButton label='Назад' to='/vocabulary' backgroundColor='#86C6A7' />
			<StyledLanguageSwitcher
				isSourceRus={isSourceRus}
				onChange={() => setIsSourceRus(!isSourceRus)}
				isSmall
			/>
			<FormGroup
				label='Введите слово (не более 30 знаков)'
				labelPlacement={FormGroupLabelPlacements.top}
				margin={FormGroupMargins.normal}
			>
				<Textarea
					value={firstWord}
					onChange={(event) => setFirstWord(event.target.value)}
				/>
			</FormGroup>
			<DividerRed />
			<FormGroup
				label='Введите перевод слово или словосочетание (не более 60 знаков)'
				labelPlacement={FormGroupLabelPlacements.top}
				margin={FormGroupMargins.normal}
			>
				<Textarea
					value={secondWord}
					onChange={(event) => setSecondWord(event.target.value)}
				/>
			</FormGroup>
			<DividerRed />
			<ButtonBlue onClick={save} style={{ alignSelf: "flex-start" }}>
				Сохранить
			</ButtonBlue>
		</Page>
	);
};

export default WordPage;
