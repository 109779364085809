import React from "react";
import { Link } from "react-router-dom";
import EditButton from "../../components/EditButton/EditButton";
import { StyledMainPage } from "./MainPageStyled";

const MainPage = () => {
	return (
		<StyledMainPage>
			<EditButton component={Link} to='/about/app'>
				о приложении SaMott
			</EditButton>
		</StyledMainPage>
	);
};

export default MainPage;
