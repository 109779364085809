import styled from "styled-components";
import getTheme from "../../utils/getTheme";
import { SelectOptionContainerProps } from "./interface";

const SelectOptionContainer = styled.div<SelectOptionContainerProps>`
  padding: 4px;
  font: 300 18px ${getTheme("fontFamily")};
  background-color: ${(props) =>
		props.isActive ? props.theme.gray : "transparent"};
  color: ${getTheme("black")};
  cursor: pointer;

  &:hover {
    background-color: ${getTheme("gray")};
  }
`;

export default SelectOptionContainer;
