import styled from "styled-components";

const Wrapper = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 1150px;
  margin: 0 auto;
  padding-right: 40px;
  height: ${(props) => props.isFullHeight && "100%"};
`;

export default Wrapper;
