import { Link } from "react-router-dom";
import styled from "styled-components";
import AddIcon from "../AddIcon";
import Button from "../Button";
import { ButtonAddProps } from "./interface";

export const ButtonAddContainer = styled.div<{
	[key: string]: unknown;
	$isActive?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  color: ${(props) => (props.$isActive ? props.theme.salad : props.theme.white)};
  cursor: pointer;
  text-decoration: none;

  ${Button} {
    height: auto;
    padding: 0;
    margin-right: 14px;
    background-color: transparent;
    color: inherit;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const ButtonAdd = (props: ButtonAddProps) => {
	const { children, onClick, $isActive } = props;

	return (
		<ButtonAddContainer
			as={props.isLink ? Link : "div"}
			to={props.isLink ? props.to : undefined}
			onClick={onClick}
			$isActive={$isActive}
		>
			<Button>{children}</Button>
			<AddIcon color='inherit' />
		</ButtonAddContainer>
	);
};

export default ButtonAdd;
