import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
	_id: string;
	phone: string;
	createdAt: string;
	firstName: string;
	lastName: string;
	email: string;
}

export interface UserById {
	_id: string;
	phone: string;
	email: string;
	createdAt: string;
	firstName: string;
	lastName: string;
	middleName: string;
	score: number;
	birthday: string | null;
}

interface UsersState {
	items: User[];
	count: number;
	user?: UserById;
}

const initialState: UsersState = {
	items: [],
	count: 0,
};

const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		setUsers: (state, action) => {
			state.items = action.payload.users;
			state.count = action.payload.usersCount;
		},
		setUser: (state, action) => {
			state.user = action.payload.users[0];
			state.count = action.payload.usersCount;
		},
		changeUser: (state, action: PayloadAction<Partial<UserById>>) => {
			state.user = { ...state.user, ...action.payload } as UserById;
		},
		clearUser: (state) => {
			state.user = undefined;
		},
	},
});

export const { setUsers, setUser, clearUser } = usersSlice.actions;

export default usersSlice.reducer;
