export interface CommonTaskData {
	_id: string;
	level: string;
	chapterId: string;
	points: number;
	active: boolean;
	number: number;
}

export enum TaskTypes {
	images = "1",
	correctTranslate = "2",
	audio = "3",
	audioFreeAnswer = "4",
	space = "5",
	video = "6",
	pairs = "7",
	freeAnswer = "8",
	wordByLetters = "9",
}

//задание с изображением
export interface TaskOne {
	type: TaskTypes.images;
	params: {
		photos: string[];
		text: string;
		answer: string;
	};
}

//выбрать правильный перевод
export interface TaskTwo {
	type: TaskTypes.correctTranslate;
	params: {
		text: string;
		answers: string[];
		answer: string;
	};
}

//аудиозадание
export interface TaskThree {
	type: TaskTypes.audio;
	params: {
		text: string;
		sound: string;
		answers: string[];
		answerArray: string[];
	};
}

//аудиозадание со свободным ответом
export interface TaskFour {
	type: TaskTypes.audioFreeAnswer;
	params: {
		sound: string;
		answer: string;
		text: string;
	};
}

//заполните пропуск
export interface TaskFive {
	type: TaskTypes.space;
	params: {
		answers: string[];
		text: string;
		answer: string;
	};
}

//видеозадание
export interface TaskSix {
	type: TaskTypes.video;
	params: {
		text: string;
		video: string;
		answers: string[];
		answerArray: string[];
	};
}

//соотнесите пары слов
export interface TaskSeven {
	type: TaskTypes.pairs;
	params: {
		text: string;
		answers: string[];
	};
}

//задание со свободным ответом
export interface TaskEight {
	type: TaskTypes.freeAnswer;
	params: {
		text: string;
		answer: string;
	};
}

//составь слово
export interface TaskNine {
	type: TaskTypes.wordByLetters;
	params: {
		text: string;
		answer: string;
	};
}

export type Task = CommonTaskData &
	(
		| TaskOne
		| TaskTwo
		| TaskThree
		| TaskFour
		| TaskFive
		| TaskSix
		| TaskSeven
		| TaskEight
		| TaskNine
	);

export type GetTaskParams<T extends TaskTypes> = Extract<
	Task,
	{ type: T }
>["params"];
