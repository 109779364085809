import { useMemo } from "react";
import styled from "styled-components";
import { useQueryParam } from "use-query-params";

import { TaskTypes } from "../../types/tasks";
import getTheme from "../../utils/getTheme";
import ButtonAdd, { ButtonAddContainer } from "../ButtonAdd";
import useRoutes from "../Router/useRoutes";
import SidebarBlock from "../SidebarBlock";
import { taskTypes } from "../../pages/TasksPage/TasksPage";
import Text from "../Text";

const Container = styled.div`
  max-width: 267px;
  margin-right: 32px;
  padding: 16px 8px;
  background-color: ${getTheme("redOpacity75")};

  ${SidebarBlock} {
    padding: 14px 6px;

    &:nth-child(2) {
      a {
        text-decoration: none;
        color: ${getTheme("white")};

        &.active {
          color: ${getTheme("salad")};
        }

        & + a {
          margin-top: 16px;
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  ${ButtonAddContainer} {
    white-space: nowrap;
  }
`;

const NavItem = styled.button<{ isActive?: boolean }>`
  background: none;
	border: none;
	color: ${({ isActive }) => getTheme(isActive ? "salad" : "white")};
	font-size: 24px;
	text-align: left;
	cursor: pointer;
	padding: 0;
	line-height: 18px;
`;

const TasksPageSidebar = () => {
	const { currentRoute } = useRoutes();

	const [taskType, setTaskType] = useQueryParam<TaskTypes | undefined>("type");

	const buttonAddIsActive = useMemo(() => {
		return currentRoute.id === "task";
	}, [currentRoute.id]);

	return (
		<Container>
			<SidebarBlock>
				<Text color="white" weight={700} size={24} textTransform="uppercase">
					Задания
				</Text>
			</SidebarBlock>
			<SidebarBlock>
				{taskTypes.map((filter) => (
					<NavItem
						key={filter.type}
						isActive={taskType === filter.type}
						onClick={() => setTaskType(filter?.type)}
					>
						<Text weight={900} size={14} textTransform="uppercase">
							{filter.label}
						</Text>
					</NavItem>
				))}
			</SidebarBlock>
			<SidebarBlock>
				<ButtonAdd isLink to="/tasks/new" $isActive={buttonAddIsActive}>
					добавить задание
				</ButtonAdd>
			</SidebarBlock>
		</Container>
	);
};

export default TasksPageSidebar;
