import { pathToRegexp } from "path-to-regexp";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "styled-components";

import AboutAppPage from "../../pages/AboutAppPage";
import AboutAuthorPage from "../../pages/AboutAuthorPage";
import AboutBannerPage from "../../pages/AboutBannerPage";
import AboutGrammarPage from "../../pages/AboutGrammarPage";
import DictionaryPage from "../../pages/DictionaryPage";
import MainPage from "../../pages/MainPage";
import TaskPage from "../../pages/TaskPage";
import ChaptersPage from "../../pages/ChaptersPage";
import ChapterPage from "../../pages/ChapterPage";
import WordPage from "../../pages/WordPage";
import TaskPageTitle from "../TaskPageTitle";
import TasksPage from "../../pages/TasksPage";
import UserPage from "../../pages/UserPage";
import UsersPage from "../../pages/UsersPage";
import homeIcon from "../../img/home.svg";
import goalIcon from "../../img/goal.svg";
import usersIcon from "../../img/users.svg";
import bookIcon from "../../img/book.svg";

const useRoutes = () => {
	const theme = useTheme();
	const location = useLocation();

	const mainRoute = useMemo(
		() => ({
			path: "*",
			id: "main",
			component: MainPage,
			header: {
				title: "админ панель",
				color: theme.slateBlue,
			},
			navbar: {
				icon: homeIcon,
				name: "главная",
			},
		}),
		[theme.slateBlue],
	);

	const routes = useMemo(() => {
		return [
			{
				path: "/chapters",
				id: "chapters",
				component: ChaptersPage,
				header: {
					title: "Главы",
					color: theme.orange,
				},
				navbar: {
					icon: goalIcon,
					name: "главы",
				},
			},
			{
				path: "/chapters/:id",
				id: "chapter",
				component: ChapterPage,
				header: {
					title: "Добавить главу",
					color: theme.orange,
				},
			},
			{
				path: "/tasks",
				id: "tasks",
				component: TasksPage,
				header: {
					title: "Задания",
					color: theme.orange,
				},
				navbar: {
					icon: goalIcon,
					name: "задания",
				},
			},
			{
				path: "/tasks/:id",
				id: "task",
				component: TaskPage,
				header: {
					title: <TaskPageTitle />,
					color: theme.orange,
				},
			},
			{
				path: "/users",
				id: "users",
				component: UsersPage,
				header: {
					title: "Пользователи",
					color: theme.green,
				},
				navbar: {
					icon: usersIcon,
					name: "пользователи",
				},
			},
			{
				path: "/users/:id",
				id: "user",
				component: UserPage,
				header: {
					title: "Пользователи",
					color: theme.green,
				},
			},
			{
				path: "/vocabulary",
				id: "vocabulary",
				component: DictionaryPage,
				header: {
					title: "Словарь",
					color: theme.slateBlue,
				},
				navbar: {
					icon: bookIcon,
					name: "словарь",
				},
			},
			{
				path: "/vocabulary/:id",
				id: "word",
				component: WordPage,
				header: {
					title: "Словарь",
					color: theme.slateBlue,
				},
			},
			{
				path: "/about/app",
				id: "aboutApp",
				component: AboutAppPage,
				header: {
					title: "О приложении Samott",
					color: "#828282",
				},
			},
			{
				path: "/about/author",
				id: "aboutAuthor",
				component: AboutAuthorPage,
				header: {
					title: "О приложении Samott",
					color: "#828282",
				},
			},
			{
				path: "/about/banner",
				id: "aboutBanner",
				component: AboutBannerPage,
				header: {
					title: "Баннер",
					color: "#828282",
				},
			},
			{
				path: "/about/grammar",
				id: "aboutGrammar",
				component: AboutGrammarPage,
				header: {
					title: "Грамматика",
					color: "#828282",
				},
			},
			mainRoute,
		];
	}, [theme, mainRoute]);

	const currentRoute = useMemo(() => {
		const result = routes.find((route) => {
			if (route === mainRoute) {
				return false;
			}

			if (Array.isArray(route.path)) {
				return route.path.some((path) =>
					pathToRegexp(path).test(location.pathname),
				);
			} else {
				return pathToRegexp(route.path).test(location.pathname);
			}
		});

		return result || mainRoute;
	}, [routes, location, mainRoute]);

	return { routes, currentRoute };
};

export default useRoutes;
