import React from "react";
import Page from "../Page";
import TasksPageSidebar from "../TasksPageSidebar";
import { TasksPageContainerProps } from "./interface";

const TasksPageContainer = ({
	children,
	className,
}: TasksPageContainerProps) => {
	return (
		<Page className={className} sidebar={<TasksPageSidebar />}>
			{children}
		</Page>
	);
};

export default TasksPageContainer;
