import styled from "styled-components";
import Text from "../Text";

const HeaderTitle = styled(Text).attrs({
	as: "h1",
	size: 36,
	weight: 700,
	color: "white",
	textTransform: "uppercase",
})`
  margin: 0 36px;
  z-index: 1;
`;

export default HeaderTitle;
