import styled from "styled-components";
import { PaperProps } from "./interface";

const Paper = styled.div<PaperProps>`
  padding: 8px 6px;
  border-radius: 7px;
  background-color: ${(props) => props.backgroundColor};
`;

export default Paper;
