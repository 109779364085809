import styled from "styled-components";

const SidebarBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;
  gap: 8px;
`;

export default SidebarBlock;
