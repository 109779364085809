import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { useQueryParam } from "use-query-params";
import { rgba } from "polished";

import FormInput from "../../components/FormInput";
import { RootState } from "../../redux/store";
import {
	clearTask,
	createTask,
	initTask,
	requestTask,
	selectTask,
	setTask,
	updateTask,
} from "../../redux/tasksSlice";
import { TaskTypes } from "../../types/tasks";
import ButtonOrange from "../../components/ButtonOrange";
import DividerRed from "../../components/DividerRed";
import FormGroup from "../../components/FormGroup";
import { FormGroupMargins } from "../../components/FormGroup/interface";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import Select from "../../components/Select";
import SelectOption from "../../components/SelectOption";
import Switch, { SwitchContainer } from "../../components/Switch";
import TaskAudioForm from "../../components/TaskAudioForm";
import TaskVideoForm from "../../components/TaskVideoForm";
import TaskCorrectTranslateForm from "../../components/TaskCorrectTranslateForm";
import TaskPairsForm from "../../components/TaskPairsForm";
import TaskImageForm from "../../components/TaskImageForm";
import TaskFreeAnswerForm from "../../components/TaskFreeAnswerForm";
import TaskWordByLettersForm from "../../components/TaskWordByLettersForm";
import TasksPageContainer from "../../components/TasksPageContainer";

import { taskTypes } from "../TasksPage/TasksPage";

import { TaskPageProps } from "./interface";
import { getChaptersAsync } from "../../redux/chaptersSlice";

const TaskPageFooter = styled(FormGroup).attrs({
	margin: FormGroupMargins.normal,
})`
  justify-content: flex-start;

  ${SwitchContainer} {
    margin-left: 27px;
  }
`;

const scoreValues = ["1", "2", "3", "4", "5", "6", "7"];

const TaskPage = ({ match, history }: TaskPageProps) => {
	const { id } = match.params;
	const theme = useTheme();
	const dispatch = useDispatch();
	const task = useSelector(selectTask);
	const { chapters } = useSelector((state: RootState) => state.chapter);

	const page = useSelector(({ tasks }: RootState) => tasks.page);

	const [type, setType] = useQueryParam<TaskTypes>("type");

	const getChapters = useCallback(() => {
		dispatch(getChaptersAsync());
	}, [dispatch]);

	useEffect(() => {
		getChapters();
	}, [getChapters]);

	useEffect(() => {
		if (id !== "new") {
			dispatch(requestTask(id, type));
		} else if (type) {
			dispatch(initTask(type));
		}
	}, [dispatch, type, id]);

	useEffect(() => {
		return () => {
			dispatch(clearTask());
		};
	}, [dispatch]);

	const handleSelectType = useCallback(
		(value: string) => {
			setType(value as TaskTypes);
		},
		[setType],
	);

	const handleSelectChapter = useCallback(
		(value: string) => {
			dispatch(
				setTask({
					chapterId: value === "test" ? undefined : value,
					level:
						value === "test"
							? "test"
							: chapters.find(({ _id }) => _id === value)?.level,
				}),
			);
		},
		[chapters, dispatch],
	);

	const handleSelectScores = useCallback(
		(value: string) => {
			dispatch(setTask({ points: Number(value) }));
		},
		[dispatch],
	);

	const handleChangeTaskActive = useCallback(
		(isActive?: boolean) => {
			dispatch(setTask({ active: isActive }));
		},
		[dispatch],
	);

	const handleChangeNumber = useCallback(
		(e: any) => {
			dispatch(setTask({ number: Number(e.target.value.replace(/\D/g, "")) }));
		},
		[dispatch],
	);

	const saveTask = useCallback(() => {
		const success = () => {
			history.push({
				pathname: "/tasks",
				search: `type=${type || TaskTypes.images}&page=${page}`,
			});
		};

		if (id !== "new") {
			dispatch(updateTask(success));
		} else {
			dispatch(createTask(success));
		}
	}, [dispatch, id, history, type, page]);

	return (
		<TasksPageContainer>
			<GoBackButton
				to={`/tasks?type=${type || TaskTypes.images}&page=${page}`}
				backgroundColor={rgba(theme.lightGrayBlue, 0.7)}
			/>

			<FormGroup label="Вид задания:" margin={FormGroupMargins.normal}>
				<Select
					minWidth={300}
					label="Тип задания"
					value={task?.type}
					onChange={handleSelectType}
				>
					{taskTypes.map((type) =>
						type.type ? (
							<SelectOption key={type.type} value={type.type}>
								{type.label}
							</SelectOption>
						) : null,
					)}
				</Select>
			</FormGroup>

			<DividerRed />

			{task?.type === TaskTypes.images && <TaskImageForm />}

			{task?.type === TaskTypes.correctTranslate && (
				<TaskCorrectTranslateForm />
			)}

			{task?.type === TaskTypes.audio && <TaskAudioForm />}

			{task?.type === TaskTypes.video && <TaskVideoForm />}

			{task?.type === TaskTypes.audioFreeAnswer && (
				<TaskAudioForm isFreeAnswer />
			)}

			{task?.type === TaskTypes.space && <TaskCorrectTranslateForm />}

			{task?.type === TaskTypes.pairs && <TaskPairsForm />}

			{task?.type === TaskTypes.freeAnswer && <TaskFreeAnswerForm />}

			{task?.type === TaskTypes.wordByLetters && <TaskWordByLettersForm />}

			{task?.type && (
				<>
					<FormGroup label="глава:" margin={FormGroupMargins.normal}>
						<Select
							onChange={handleSelectChapter}
							label="не выбрано"
							value={String(task.chapterId)}
							minWidth={200}
						>
							<SelectOption value="test">Тест на знание языка</SelectOption>
							{chapters.map((chapter) => (
								<SelectOption key={chapter._id} value={chapter._id}>
									{`уровень ${chapter.level} - ${chapter.title}`}
								</SelectOption>
							))}
						</Select>
					</FormGroup>

					<DividerRed />

					<FormGroup label="номер:" margin={FormGroupMargins.normal}>
						<FormInput value={task.number} onChange={handleChangeNumber} />
					</FormGroup>

					<DividerRed />

					<FormGroup label="баллы:" margin={FormGroupMargins.normal}>
						<Select
							onChange={handleSelectScores}
							label="не выбрано"
							value={String(task.points)}
							minWidth={200}
						>
							{scoreValues.map((score) => (
								<SelectOption key={score} value={score}>
									{score}
								</SelectOption>
							))}
						</Select>
					</FormGroup>
				</>
			)}

			{task && task.points > 0 && (
				<>
					<DividerRed />
					<TaskPageFooter>
						<ButtonOrange onClick={saveTask}>сохранить</ButtonOrange>
						<Switch
							onChange={handleChangeTaskActive}
							isChecked={task.active}
							startLabel="закрытый"
							endLabel="открытый"
						/>
					</TaskPageFooter>
				</>
			)}
		</TasksPageContainer>
	);
};

export default TaskPage;
