import styled from "styled-components";
import { StyledEditButton } from "../../components/EditButton/EditButtonStyled";
import Page from "../../components/Page";

export const StyledMainPage = styled(Page)`
  ${StyledEditButton} {
    align-self: center;
    margin-top: auto;
  }
`;
