import { useMemo } from "react";
import styled from "styled-components";
import NavBarLink from "../NavBarLink";
import useRoutes from "../Router/useRoutes";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

const NavBar = () => {
	const { routes } = useRoutes();

	const links = useMemo(
		() =>
			routes
				.filter(
					(route) => route.navbar && route.navbar.name && route.navbar.icon,
				)
				.sort((routeA) => (routeA.id === "main" ? -1 : 1))
				.map((route) => ({
					label: route.navbar!.name as string,
					icon: route.navbar!.icon as string,
					to: (() => {
						if (Array.isArray(route.path)) {
							return route.path[0];
						}
						return route.path === "*" ? "/" : route.path;
					})(),
				})),
		[routes],
	);

	return (
		<Container>
			{links.map((link) => (
				<NavBarLink {...link} key={link.to} />
			))}
		</Container>
	);
};

export default NavBar;
